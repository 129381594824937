/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';

import FlagIcon from './FlagIcon';

import i18n, { languages } from '../../i18n';

const LanguageButton: React.FC = () => {
  const [language, setLanguage] = React.useState(i18n.language);

  const seti18nLang = (lang: string) =>
    i18n.changeLanguage(lang, () => setLanguage(lang));

  const flag = (langCode: string) => {
    let code = langCode;

    if (langCode === 'en') code = 'us';
    else if (langCode === 'nl-NL') code = 'nl';
    else if (langCode === 'en-US') code = 'us';

    return <FlagIcon code={code} />;
  };

  if (languages.length < 2) return null;

  return (
    <div className="language-button">
      <div className="btn-group dropup">
        <button
          type="button"
          className="btn btn-light btn-circle btn-sm border border-secondary"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {flag(language)}
        </button>
        <div className="dropdown-menu dropdown-language">
          {languages.map((code) => (
            <button
              type="button"
              className="dropdown-item"
              onClick={() => seti18nLang(code)}
              key={code}
            >
              {flag(code)}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LanguageButton;

import * as React from 'react';
import { Translation } from 'react-i18next';
import Plyr from 'plyr';
import Hls from 'hls.js';

import {
  IRegistrationWebinarStream,
  IRegistrationWebinarSettings,
  IRegistrationWebinarBreakoutSession,
} from '../../../interfaces/IRegistration';
import { IStatement } from '../../../interfaces/IStatement';

import CustomButton from './CustomButton';
import TranslateString from '../../components/Translation';
import { ISurveyQuestion } from '../../../interfaces/ISurvey';
import Survey from './Survey';

export interface VideoPlayerProps {
  className?: string;
  stream: IRegistrationWebinarStream;
  statement?: IStatement;
  message?: string;
  nameRequired: boolean;
  disableQuestions: boolean;
  settings: IRegistrationWebinarSettings;
  speeddatesStartDate?: moment.Moment;
  breakoutSessions?: IRegistrationWebinarBreakoutSession[];
  survey?: ISurveyQuestion[];
  surveyInput?: string;
  onStatementAnswer: (id: number, answer: number) => void;
  onStatementDissmiss: () => void;
  onMessageDissmiss: () => void;
  onSendQuestion: (question: { name?: string; question: string }) => void;
  onJoinSpeeddates: (name?: string) => void;
  onSurveyQuestionAnswer: (id: number, answer?: string | number) => void;
  onSetSurveyInput: (surveyInput: string) => void;
  isVOD: boolean;
}

export interface VideoPlayerState {
  hoverQuestionButton: boolean;
  questionToggle: boolean;
  name: string;
  question: string;
  questionSent: boolean;
  fullscreen: boolean;
  wasFullscreenBeforeStatement: boolean;
  wasFullscreenBeforeQuestion: boolean;
  wasFullscreenBeforeSurvey: boolean;
  speeddatesDissmissed: boolean;
  breakoutSessionsDissmissed: boolean;
}

class VideoPlayer extends React.Component<VideoPlayerProps, VideoPlayerState> {
  player: Plyr | undefined;

  playerRef = React.createRef<HTMLDivElement>();

  constructor(props: VideoPlayerProps) {
    super(props);

    this.state = {
      hoverQuestionButton: false,
      questionToggle: false,
      name: '',
      question: '',
      questionSent: false,
      fullscreen: false,
      wasFullscreenBeforeStatement: false,
      wasFullscreenBeforeQuestion: false,
      wasFullscreenBeforeSurvey: false,
      speeddatesDissmissed: false,
      breakoutSessionsDissmissed: false,
    };
  }

  componentDidMount() {
    const {
      stream: { type, src },
      isVOD,
    } = this.props;
    const video = document.querySelector('video');

    if (video && isVOD) {
      const options: Record<string, unknown> = {
        controls: [
          'play-large',
          'play',
          'settings',
          'progress',
          'mute',
          'volume',
          'fullscreen',
        ],
        settings: ['quality'],
        fullscreen: {
          enabled: true,
          iosNative: true,
        },
      };

      if (type === 'hls' && Hls.isSupported()) {
        const hls = new Hls();

        // Hls.js neemt het switchen van quality/level over. Zodra Plyr hierin
        // gaat deelnemen gaat het dus he-le-maal mis. Blijkbaar set Plyr direct
        // na het starten het level in de API van Hls.js waardoor deze nooit
        // meer op auto terecht komt.

        // const updateQuality = (quality: number) => {
        //   hls.levels.forEach((level, index) => {
        //     if (level.bitrate === quality * 1000000) hls.currentLevel = index;
        //   });
        // };

        hls.loadSource(src);

        hls.on(Hls.Events.MANIFEST_PARSED, (_event, _data) => {
          // const availableQualities = hls.levels
          //   .map(({ bitrate }) => {
          //     if (bitrate === 3500000) return 1080;
          //     if (bitrate === 2000000) return 720;
          //     return 480;
          //   })
          //   .sort((a, b) => b - a);

          // options.quality = {
          //   default: availableQualities[0],
          //   options: availableQualities,
          //   forced: true,
          //   onChange: (e: number) => updateQuality(e),
          // };

          this.player = new Plyr(video, options);
          this.registerPlayerEventHandlers();
        });

        hls.attachMedia(video);
      } else {
        this.player = new Plyr(video, options);
        this.registerPlayerEventHandlers();

        this.player.source = {
          type: 'video',
          sources: [
            {
              src,
              provider: type === 'youtube' ? 'youtube' : 'html5',
            },
          ],
        };
      }
    } else if (video) {
      const options: Record<string, unknown> = {
        controls: [
          'play-large',
          'play',
          'mute',
          'volume',
          'settings',
          'fullscreen',
        ],
        settings: ['quality'],
        fullscreen: {
          enabled: true,
          iosNative: true,
        },
      };

      if (type === 'hls' && Hls.isSupported()) {
        const hls = new Hls();

        // Hls.js neemt het switchen van quality/level over. Zodra Plyr hierin
        // gaat deelnemen gaat het dus he-le-maal mis. Blijkbaar set Plyr direct
        // na het starten het level in de API van Hls.js waardoor deze nooit
        // meer op auto terecht komt.

        // const updateQuality = (quality: number) => {
        //   hls.levels.forEach((level, index) => {
        //     if (level.bitrate === quality * 1000000) hls.currentLevel = index;
        //   });
        // };

        hls.loadSource(src);

        hls.on(Hls.Events.MANIFEST_PARSED, (_event, _data) => {
          // const availableQualities = hls.levels
          //   .map(({ bitrate }) => {
          //     if (bitrate === 3500000) return 1080;
          //     if (bitrate === 2000000) return 720;
          //     return 480;
          //   })
          //   .sort((a, b) => b - a);

          // options.quality = {
          //   default: availableQualities[0],
          //   options: availableQualities,
          //   forced: true,
          //   onChange: (e: number) => updateQuality(e),
          // };

          this.player = new Plyr(video, options);
          this.registerPlayerEventHandlers();
        });

        hls.attachMedia(video);
      } else {
        this.player = new Plyr(video, options);
        this.registerPlayerEventHandlers();

        this.player.source = {
          type: 'video',
          sources: [
            {
              src,
              provider: type === 'youtube' ? 'youtube' : 'html5',
            },
          ],
        };
      }
    }
  }

  componentDidUpdate({
    statement: prevStatement,
    breakoutSessions: prevBreakoutSessions,
    survey: prevSurvey,
  }: VideoPlayerProps) {
    const { statement, breakoutSessions, survey } = this.props;

    this.checkFullscreenStateForStatement(prevStatement, statement);
    this.checkFullscreenStateForSurvey(prevSurvey, survey);

    if (!prevBreakoutSessions && breakoutSessions)
      this.setPlayerFullscreen(false);

    if (!!prevBreakoutSessions && !breakoutSessions)
      this.handleDissmissBreakoutSessions(false);
  }

  registerPlayerEventHandlers = () => {
    if (this.player) {
      this.player.on('enterfullscreen', () => this.statePlayerFullscreen(true));
      this.player.on('exitfullscreen', () => {
        this.statePlayerFullscreen(false);
        this.scrollToPlayer();
      });
    }
  };

  checkFullscreenStateForStatement = (
    previousStatement: IStatement | undefined,
    currentStatement: IStatement | undefined,
  ): void => {
    if (
      !previousStatement &&
      currentStatement &&
      this.player?.fullscreen.active
    ) {
      this.setPlayerFullscreen(false);
      this.setState({ wasFullscreenBeforeStatement: true });
    } else if (previousStatement && !currentStatement)
      this.setState(({ wasFullscreenBeforeStatement: wasFullscreen }) => {
        if (wasFullscreen) this.setPlayerFullscreen(true);

        return { wasFullscreenBeforeStatement: false };
      });
  };

  checkFullscreenStateForSurvey = (
    prevSurvey?: ISurveyQuestion[],
    survey?: ISurveyQuestion[],
  ): void => {
    if (!prevSurvey && survey && this.player?.fullscreen.active) {
      this.setPlayerFullscreen(false);
      this.setState({ wasFullscreenBeforeSurvey: true });
    } else if (prevSurvey && !survey)
      this.setState(({ wasFullscreenBeforeSurvey: wasFullscreen }) => {
        if (wasFullscreen) this.setPlayerFullscreen(true);

        return { wasFullscreenBeforeSurvey: false };
      });
  };

  scrollToPlayer = () => {
    if (this.playerRef.current) {
      const { offsetTop } = this.playerRef.current;
      let offset = offsetTop - 25;

      if (offset < 0) offset = 0;

      window.scrollTo(0, offset);
    }
  };

  overlayActive = (): boolean => {
    const {
      statement,
      message,
      speeddatesStartDate,
      breakoutSessions,
      survey,
    } = this.props;
    const {
      questionToggle,
      speeddatesDissmissed,
      breakoutSessionsDissmissed,
    } = this.state;

    if (statement) return true;
    if (questionToggle) return true;
    if (message) return true;
    if (!!speeddatesStartDate && !speeddatesDissmissed) return true;
    if (!!breakoutSessions && !breakoutSessionsDissmissed) return true;
    if (survey) return true;

    return false;
  };

  sendQuestion = () => {
    const { onSendQuestion } = this.props;
    const { question, name } = this.state;

    const send: { name?: string; question: string } = { question };
    if (name) send.name = name;

    onSendQuestion(send);

    this.setState(
      {
        question: '',
        name: '',
        questionSent: true,
      },
      () => setTimeout(() => this.questionToggle(false), 2000),
    );
  };

  setPlayerFullscreen = (enter: boolean) => {
    if (enter) {
      this.player?.fullscreen.enter();
    } else {
      this.player?.fullscreen.exit();
    }
  };

  statePlayerFullscreen = (fullscreen: boolean) =>
    this.setState({ fullscreen });

  questionToggle = (questionToggle: boolean) => {
    const isFullscreen = this.player?.fullscreen.active ?? false;
    const { wasFullscreenBeforeQuestion } = this.state;

    if (questionToggle) {
      if (isFullscreen) this.setPlayerFullscreen(false);

      this.setState({
        hoverQuestionButton: false,
        wasFullscreenBeforeQuestion: isFullscreen,
      });
    } else if (!questionToggle && wasFullscreenBeforeQuestion)
      this.setPlayerFullscreen(true);

    this.setState({ questionToggle, questionSent: false });
  };

  handleDissmissSpeeddates = (speeddatesDissmissed: boolean) =>
    this.setState({ speeddatesDissmissed });

  handleDissmissBreakoutSessions = (breakoutSessionsDissmissed: boolean) =>
    this.setState({ breakoutSessionsDissmissed });

  render() {
    const {
      statement,
      message,
      nameRequired,
      disableQuestions,
      settings,
      survey,
      surveyInput,
      onStatementAnswer,
      onStatementDissmiss,
      onMessageDissmiss,
      onSurveyQuestionAnswer,
      onSetSurveyInput,
    } = this.props;
    const {
      hoverQuestionButton,
      questionToggle,
      question,
      name,
      questionSent,
      fullscreen,
    } = this.state;
    const overlayActive = this.overlayActive();
    const { textColor, backgroundColor, accentColor } = settings;

    return (
      <div className="player-wrapper embed-wrapper" ref={this.playerRef}>
        <div className={`mask${overlayActive ? ' mask-dimmed' : ''}`}>
          <div className={`embed player${overlayActive ? ' player-blur' : ''}`}>
            <video
              preload="none"
              id="player"
              crossOrigin="true"
              controls
              playsInline
            />
          </div>

          {!overlayActive && (
            <>
              {!disableQuestions && (
                <div
                  className="text-gray-100"
                  style={{
                    alignContent: 'center',
                    right: 20,
                    top: 20,
                    zIndex: 9999999,
                    position: fullscreen ? 'fixed' : 'absolute',
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-icon-split btn-sm"
                    style={{
                      backgroundColor: hoverQuestionButton
                        ? accentColor
                        : backgroundColor,
                      color: textColor,
                    }}
                    onMouseEnter={() =>
                      this.setState({ hoverQuestionButton: true })
                    }
                    onMouseLeave={() =>
                      this.setState({ hoverQuestionButton: false })
                    }
                    onClick={() => this.questionToggle(true)}
                  >
                    <span className="icon">
                      <i className="fas fa-question" />
                    </span>
                    <span className="text d-none d-lg-inline ml-2">
                      <TranslateString i18nKey="webinar.player.askAQuestion" />
                    </span>
                  </button>
                </div>
              )}
            </>
          )}
        </div>
        <div
          className={`dynamic-wrapper p-3${
            overlayActive ? ' overlay-active' : ''
          } w-100 text-center`}
        >
          {message && (
            <div className="text-gray-100 mt-3 mt-md-5">
              <i
                className="fa fa-comment-dots"
                style={{ fontSize: 60, color: backgroundColor }}
              />
              <h1 className="h4 mt-3 mb-5 mt-md-5 px-5 text-gray-100">
                {message}
              </h1>

              <Translation>
                {(t) => (
                  <CustomButton
                    className="btn-outline-dark btn-lg"
                    text={t('common.close')}
                    onClick={onMessageDissmiss}
                  />
                )}
              </Translation>
            </div>
          )}

          {survey && (
            <div className="mt-0 mt-md-5">
              <i
                className="fa fa-question-circle d-none d-md-block"
                style={{ fontSize: 60, color: backgroundColor }}
              />
              <Survey
                surveyQuestions={survey}
                settings={settings}
                closeButton
                surveyInput={surveyInput}
                onSurveyQuestionAnswer={onSurveyQuestionAnswer}
                onSetSurveyInput={onSetSurveyInput}
              />
            </div>
          )}
          {statement && (
            <>
              <h2 className="h3 mb-1 mb-md-5 text-gray-100 mt-md-5 mt-0">
                <span className="font-weight-bold font-italic">
                  <TranslateString i18nKey="webinar.player.statement" />:{' '}
                </span>
                {statement.statement}
              </h2>
              <div className="row">
                {statement.answers.map((answer, key) => (
                  <div
                    className="col-12 col-md-6 mt-2 mt-md-4"
                    // eslint-disable-next-line react/no-array-index-key
                    key={key}
                  >
                    <CustomButton
                      className="btn-lg btn-block"
                      text={answer}
                      textColor={textColor}
                      backgroundColor={backgroundColor}
                      accentColor={accentColor}
                      onClick={() => onStatementAnswer(statement.id, key)}
                    />
                  </div>
                ))}
                <div className="col-12 col-md-6 mt-2 mt-md-4">
                  <Translation>
                    {(t) => (
                      <CustomButton
                        className="btn-outline-dark btn-lg btn-block"
                        text={t('webinar.player.noOpinion')}
                        onClick={onStatementDissmiss}
                      />
                    )}
                  </Translation>
                </div>
              </div>
            </>
          )}
          {!disableQuestions &&
            !statement &&
            questionToggle &&
            (questionSent ? (
              <div className="text-gray-100 mt-3 mt-md-5">
                <i
                  className="fa fa-check-circle text-success"
                  style={{ fontSize: 60 }}
                />
                <h1 className="h4 mt-3 mt-md-5 text-gray-100 font-weight-bold">
                  <TranslateString i18nKey="webinar.player.questionSent" />
                </h1>
              </div>
            ) : (
              <>
                <h2 className="h3 mb-2 mb-md-5 text-gray-100 mt-md-5 mt-0">
                  <span className="font-weight-bold">
                    <TranslateString i18nKey="webinar.player.askAQuestion" />
                  </span>
                </h2>
                <form className="form">
                  {nameRequired && (
                    <div className="row justify-content-center">
                      <div className="form-group col-12 col-md-8 mb-2 mb-md-3">
                        <Translation>
                          {(t) => (
                            <input
                              className="form-control"
                              placeholder={t('common.yourName')}
                              onChange={(e) => {
                                this.setState({
                                  name: e.currentTarget.value,
                                });
                              }}
                              value={name}
                              required
                            />
                          )}
                        </Translation>
                      </div>
                    </div>
                  )}
                  <div className="row justify-content-center">
                    <div
                      className={`form-group col-12 col-md-8 mb-${
                        nameRequired ? '2' : 4
                      } mb-md-3`}
                    >
                      <Translation>
                        {(t) => (
                          <textarea
                            className="form-control"
                            placeholder={`${t(
                              'webinar.player.typeYourQuestion',
                            )}...`}
                            onChange={(e) => {
                              this.setState({
                                question: e.currentTarget.value,
                              });
                            }}
                            value={question}
                            rows={nameRequired ? 2 : 3}
                            required
                          />
                        )}
                      </Translation>
                    </div>
                  </div>
                  <div className="form-group">
                    <Translation>
                      {(t) => (
                        <>
                          <CustomButton
                            className="mr-2"
                            icon="paper-plane"
                            text={t('common.send')}
                            textColor={textColor}
                            backgroundColor={backgroundColor}
                            accentColor={accentColor}
                            onClick={this.sendQuestion}
                            disabled={!question || (nameRequired && !name)}
                          />
                          <CustomButton
                            className="btn-dark"
                            icon="times"
                            text={t('common.cancel')}
                            onClick={() => this.questionToggle(false)}
                          />
                        </>
                      )}
                    </Translation>
                  </div>
                </form>
              </>
            ))}
        </div>
      </div>
    );
  }
}

export default VideoPlayer;

import * as React from 'react';
import { Translation } from 'react-i18next';

import {
  IRegistrationWebinarBreakoutSession,
  IRegistrationWebinarSettings,
} from '../../../../interfaces/IRegistration';
import { IPlayerJoinedBreakoutSession } from '../../../../interfaces/IPlayer';

import CustomButton from '../CustomButton';

export interface BreakoutSessionSelectorCardProps {
  breakoutSession: IRegistrationWebinarBreakoutSession;
  settings: IRegistrationWebinarSettings;
  index: number;
  totalBreakoutSessions: number;
  participantName?: string;
  handleJoinBreakoutSession: (values: IPlayerJoinedBreakoutSession) => void;
}

const BreakoutSessionSelectorCard: React.SFC<BreakoutSessionSelectorCardProps> = ({
  breakoutSession: {
    name: roomName,
    description,
    identifier,
    ended,
    withChat,
    canJoin,
  },
  settings: { backgroundColor, accentColor, textColor },
  index,
  totalBreakoutSessions,
  participantName,
  handleJoinBreakoutSession,
}) => {
  return (
    <div
      className={`col-12 ${
        !(index === totalBreakoutSessions - 1 && index % 2 === 0) && 'col-xl-6'
      } mb-3 mb-xl-4 align-items-stretch`}
      key={identifier}
    >
      <div className="card h-100">
        <div className="card-body d-flex flex-column">
          <h5 className="card-title">{roomName}</h5>
          {description && <p className="card-text">{description}</p>}
          <Translation>
            {(t) => (
              <CustomButton
                className="mt-auto"
                text={
                  ended
                    ? t('webinar.breakoutSessions.sessionIsEnded')
                    : canJoin
                    ? `${t(
                        'webinar.breakoutSessions.participateIn',
                      )} ${roomName}`
                    : t('webinar.breakoutSessions.waitingForHost')
                }
                textColor={textColor}
                backgroundColor={backgroundColor}
                accentColor={accentColor}
                icon="sign-in-alt"
                onClick={() =>
                  handleJoinBreakoutSession({
                    roomName,
                    identifier,
                    participantName,
                    withChat,
                  })
                }
                disabled={!canJoin || ended}
              />
            )}
          </Translation>
        </div>
      </div>
    </div>
  );
};

export default BreakoutSessionSelectorCard;

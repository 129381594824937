import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Translation } from 'react-i18next';
import moment from 'moment-timezone';

import { IWebinarInfo } from '../../interfaces/IWebinar';

import HttpService from '../../services/HttpService';
import config from '../../config';
import i18n from '../../i18n';

import History from '../../utilities/History';

import logo from '../../assets/images/qbroadcasting_logo.png';

interface MatchParams {
  registrationKey: string;
}

interface IWebinarInfoState extends Omit<IWebinarInfo, 'start'> {
  start: string;
}

export type RegistrationProps = RouteComponentProps<MatchParams>;

export interface RegistrationState {
  registrationKey: string;
  webinarInfo?: IWebinarInfoState;
  firstname: string;
  lastname: string;
  company: string;
  email: string;
  phone: string;
  custom1: string;
  custom2: string;
  custom3: string;
  acceptTerms: boolean;
  registering: boolean;
  registered: boolean;
}

class Registration extends React.Component<
  RegistrationProps,
  RegistrationState
> {
  constructor(props: RegistrationProps) {
    super(props);

    const {
      match: {
        params: { registrationKey },
      },
    } = this.props;

    this.state = {
      registrationKey,
      firstname: '',
      lastname: '',
      company: '',
      email: '',
      phone: '',
      custom1: '',
      custom2: '',
      custom3: '',
      acceptTerms: false,
      registering: false,
      registered: false,
    };
  }

  componentDidMount() {
    document.body.classList.add('bg-light');
    document.title = 'Registratie Webinar';

    const {
      location: { search },
    } = this.props;
    const { registrationKey } = this.state;
    const email = new URLSearchParams(search).get('email');

    this.setState({ email: email ?? '' });
    this.fetchWebinarData(registrationKey);
  }

  componentWillUnmount() {
    document.body.classList.remove('bg-light');
    document.body.classList.remove('samsung');
  }

  fetchWebinarData = async (registrationKey: string): Promise<void> => {
    try {
      const {
        data: { start, name, ...webinarInfo },
      } = (await HttpService.get(
        `/register/webinar-info?key=${registrationKey}`,
      )) as {
        data: IWebinarInfo;
      };

      if (webinarInfo.id === 164) {
        document.body.classList.remove('bg-light');
        document.body.classList.add('samsung');

        i18n.changeLanguage('en-US');
      }

      let startDate = moment(start).format('LLLL');

      if ([76, 77, 78].includes(webinarInfo.id))
        startDate = moment(start).tz('Europe/Amsterdam').format('LLLL zz');
      else if ([79, 80].includes(webinarInfo.id))
        startDate = `${moment(start).tz('Asia/Bangkok').format('LLLL')} ICT`;
      else if ([82, 83, 84, 86].includes(webinarInfo.id))
        startDate = moment(start).tz('America/New_York').format('LLLL zz');

      this.setState(
        {
          webinarInfo: {
            ...webinarInfo,
            name,
            start: startDate,
          },
        },
        () => {
          document.title = name;
          if (webinarInfo.id === 88) {
            document.body.classList.add('custom-ns');
          }
        },
      );
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400)
          toast.warn(
            <Translation>
              {(t) => t('registrationForm.notifications.noValidWebinarId')}
            </Translation>,
          );
        else if (e.response.status === 404)
          toast.error(
            <Translation>
              {(t) => t('registrationForm.notifications.webinarNotFound')}
            </Translation>,
          );
        else
          toast.error(
            <Translation>
              {(t) => t('common.notifications.somethingWentWrong')}
            </Translation>,
          );
      } else
        toast.error(
          <Translation>
            {(t) => t('common.notifications.somethingWentWrong')}
          </Translation>,
        );
    }
  };

  onInputChange = ({
    currentTarget: { id, value, type, checked },
  }: React.ChangeEvent<HTMLInputElement>): void =>
    this.setState((s) => ({
      ...s,
      [id]: type === 'checkbox' ? checked : value,
    }));

  onRabobankInputChange = (
    value: 'Ja, is klant' | 'Nee, is geen klant',
  ): void => {
    this.setState((s) => ({
      ...s,
      custom2: value,
    }));
  };

  onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    this.setState({ registering: true }, async () => {
      setTimeout(async () => {
        try {
          const {
            firstname,
            lastname,
            company,
            email,
            phone,
            custom1,
            custom2,
            custom3,
            registrationKey,
          } = this.state;

          const { data } = await HttpService.post('/register', {
            firstname,
            lastname,
            company,
            email,
            phone,
            custom1,
            custom2,
            custom3,
            registrationKey,
          });

          if (typeof data === 'object' && data.identifier) {
            History.push(`/player/${data.identifier}`);
          } else this.setState({ registered: true });
        } catch (err) {
          this.setState({ registering: false }, () => {
            if (err.response) {
              if (err.response.status === 409)
                toast.error(
                  <Translation>
                    {(t) =>
                      t('registrationForm.notifications.emailAlreadyExists')
                    }
                  </Translation>,
                );
              else if (err.response.data && err.response.data.message)
                toast.error(err.response.data.message);
              else
                toast.error(
                  `${(
                    <Translation>
                      {(t) => t('common.notifications.serverError')}
                    </Translation>
                  )}: ${err.response.status} ${err.response.statusText}`,
                );
            } else
              toast.error(
                <Translation>
                  {(t) => t('common.notifications.somethingWentWrong')}
                </Translation>,
              );
          });
        }
      }, 300);
    });
  };

  render() {
    const {
      webinarInfo,
      firstname,
      lastname,
      company,
      email,
      phone,
      custom1,
      custom2,
      custom3,
      acceptTerms,
      registering,
      registered,
    } = this.state;

    const year = moment().year();
    const yearInfo = year > 2020 ? `2020 - ${year}` : `${year}`;

    const {
      api: { endpoint },
    } = config;

    return (
      <div className="container">
        {!webinarInfo ? (
          <div className="py-5 text-center">
            <h2>
              <Translation>
                {(t) => t('registrationForm.registrationWebinar')}
              </Translation>
            </h2>
            <div className="lead mt-5">
              <div className="spinner-border" role="status">
                <span className="sr-only">
                  <Translation>
                    {(t) =>
                      `${t('common.loading').charAt(0).toUpperCase()}${t(
                        'common.loading',
                      ).slice(1)}`
                    }
                  </Translation>
                  ...
                </span>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="py-5">
              <img
                className="d-block mx-auto mb-5 webinar-logo"
                src={
                  webinarInfo.logo
                    ? `${endpoint}/static/logo/${webinarInfo.logo}`
                    : logo
                }
                alt="Logo"
              />
              <div className="text-center">
                <h1>{webinarInfo.name}</h1>
                <h3 className="text-black-50">
                  <Translation>
                    {(t) => t('registrationForm.title')}
                  </Translation>
                </h3>
              </div>
              {webinarInfo.agenda && (
                <p
                  className="lead mt-5"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: webinarInfo.agenda,
                  }}
                />
              )}
            </div>

            <div className="box">
              {registered ? (
                <div className="row">
                  <div className="col-md-12 text-center">
                    <h3 className="display-3">
                      <i className="fas fa-check-circle fa-fw text-success" />
                    </h3>
                    <h1 className="h2">
                      <Translation>
                        {(t) => t('registrationForm.registered.title')}
                      </Translation>
                    </h1>
                    <span className="text-muted">
                      <Translation>
                        {(t) => t('registrationForm.registered.content')}
                      </Translation>
                    </span>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-md-4 order-md-2 mb-4">
                    <h4 className="d-flex justify-content-between align-items-center mb-3">
                      <Translation>
                        {(t) => t('registrationForm.information.title')}
                      </Translation>
                    </h4>
                    <ul className="list-group mb-3">
                      <li className="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                          <h6 className="my-0">
                            <Translation>
                              {(t) =>
                                `${t('common.webinar')
                                  .charAt(0)
                                  .toUpperCase()}${t('common.webinar').slice(
                                  1,
                                )}`
                              }
                            </Translation>
                          </h6>
                          <small className="text-muted">
                            {webinarInfo.name}
                          </small>
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between bg-light">
                        <div>
                          <h6 className="my-0">
                            <Translation>
                              {(t) =>
                                `${t('common.date').charAt(0).toUpperCase()}${t(
                                  'common.date',
                                ).slice(1)}`
                              }
                            </Translation>
                          </h6>
                          <small className="text-muted">
                            {webinarInfo.start}
                          </small>
                        </div>
                      </li>
                    </ul>

                    {webinarInfo.registerEmail && (
                      <div className="card p-2 mb-3">
                        <span className="text-muted text-justify">
                          <Translation>
                            {(t) =>
                              t(
                                'registrationForm.information.emailInstructions',
                              )
                            }
                          </Translation>
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="col-md-8 order-md-1">
                    <h4 className="mb-3">
                      <Translation>
                        {(t) => t('registrationForm.userData.title')}
                      </Translation>
                    </h4>
                    <form className="needs-validation" onSubmit={this.onSubmit}>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label htmlFor="firstName">
                            <Translation>
                              {(t) => t('registrationForm.userData.firstname')}
                            </Translation>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="firstname"
                            placeholder=""
                            value={firstname}
                            onChange={this.onInputChange}
                            disabled={registering}
                            required
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <label htmlFor="lastName">
                            <Translation>
                              {(t) => t('registrationForm.userData.lastname')}
                            </Translation>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="lastname"
                            placeholder=""
                            value={lastname}
                            onChange={this.onInputChange}
                            disabled={registering}
                            required
                          />
                        </div>
                      </div>

                      {webinarInfo.registerCompany && (
                        <div className="mb-3">
                          <label htmlFor="address">
                            <Translation>
                              {(t) => t('registrationForm.userData.company')}
                            </Translation>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="company"
                            value={company}
                            onChange={this.onInputChange}
                            disabled={registering}
                            required
                          />
                        </div>
                      )}

                      {webinarInfo.registerCustom2 && webinarInfo.id === 204 && (
                        <div className="mb-3">
                          <label htmlFor="custom2">
                            {webinarInfo.registerCustom2}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="custom2"
                            value={custom2}
                            onChange={this.onInputChange}
                            disabled={registering}
                            required
                          />
                        </div>
                      )}

                      {webinarInfo.registerEmail && (
                        <div className="mb-3">
                          <label htmlFor="email">
                            <Translation>
                              {(t) => t('registrationForm.userData.email')}
                            </Translation>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            value={email}
                            onChange={this.onInputChange}
                            disabled={registering}
                            required
                          />
                        </div>
                      )}

                      {webinarInfo.registerPhone && (
                        <div className="mb-3">
                          <label htmlFor="email">
                            <Translation>
                              {(t) =>
                                t('registrationForm.userData.phonenumber')
                              }
                            </Translation>
                          </label>
                          <input
                            type="phone"
                            className="form-control"
                            id="phone"
                            value={phone}
                            onChange={this.onInputChange}
                            disabled={registering}
                            pattern="[0-9]*"
                            minLength={10}
                            maxLength={10}
                            required
                          />
                        </div>
                      )}

                      {webinarInfo.registerCustom1 && (
                        <div className="mb-3">
                          <label htmlFor="custom1">
                            {webinarInfo.registerCustom1}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="custom1"
                            value={custom1}
                            onChange={this.onInputChange}
                            disabled={registering}
                            required
                          />
                        </div>
                      )}

                      {webinarInfo.registerCustom2 && webinarInfo.id !== 204 && (
                        <div className="mb-3">
                          <label htmlFor="custom2">
                            {webinarInfo.registerCustom2}
                          </label>
                          {webinarInfo.id === 203 ? (
                            <div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="isCustomer"
                                  id="customerYes"
                                  value="Ja, is klant"
                                  onClick={() =>
                                    this.onRabobankInputChange('Ja, is klant')
                                  }
                                  checked={custom2 === 'Ja, is klant'}
                                  required
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="customerYes"
                                >
                                  Ja, ik ben klant van de Rabobank
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="isCustomer"
                                  id="customerNo"
                                  value="Nee, is geen klant"
                                  onClick={() =>
                                    this.onRabobankInputChange(
                                      'Nee, is geen klant',
                                    )
                                  }
                                  checked={custom2 === 'Nee, is geen klant'}
                                  required
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="customerNo"
                                >
                                  Nee, ik ben geen klant van de Rabobank
                                </label>
                              </div>
                            </div>
                          ) : (
                            <input
                              type="text"
                              className="form-control"
                              id="custom2"
                              value={custom2}
                              onChange={this.onInputChange}
                              disabled={registering}
                              required
                            />
                          )}
                        </div>
                      )}

                      {webinarInfo.registerCustom3 && (
                        <div className="mb-3">
                          <label htmlFor="custom3">
                            {webinarInfo.registerCustom3}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="custom3"
                            value={custom3}
                            onChange={this.onInputChange}
                            disabled={registering}
                            required
                          />
                        </div>
                      )}

                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="acceptTerms"
                          onChange={this.onInputChange}
                          checked={acceptTerms}
                          // value={acceptTerms}
                          disabled={registering}
                          required
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="acceptTerms"
                        >
                          {/* Ja, ik heb kennis genomen van het{' '}
                        <a
                          href="/privacy"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          privacybeleid
                        </a>{' '}
                        van Q Broadcasting en geef uitdrukkelijk toestemming
                        voor het verwerken van de door mij ingevoerde gegevens
                        om deel te kunnen nemen aan het webinar. */}
                          <Translation>
                            {(t) => (
                              <div>
                                {t('registrationForm.userData.consent.pre')}{' '}
                                <Link to="/privacy" target="_blank">
                                  {t('registrationForm.userData.consent.link')}
                                </Link>{' '}
                                {t('registrationForm.userData.consent.post')}
                              </div>
                            )}
                          </Translation>
                        </label>
                      </div>

                      <button
                        className="btn btn-primary btn-lg btn-block mt-4"
                        type="submit"
                      >
                        {registering && (
                          <div
                            className="spinner-border spinner-border-sm mb-1 mr-2"
                            role="status"
                          >
                            <span className="sr-only">
                              <Translation>
                                {(t) =>
                                  `${t('common.loading')
                                    .charAt(0)
                                    .toUpperCase()}${t('common.loading').slice(
                                    1,
                                  )}`
                                }
                              </Translation>
                              ...
                            </span>
                          </div>
                        )}
                        <Translation>
                          {(t) => t('registrationForm.userData.submit')}
                        </Translation>
                      </button>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        <footer className="my-5 pt-2 text-muted text-center text-small">
          &copy; {yearInfo} |{' '}
          <a
            href="https://www.qbroadcasting.tv"
            target="_blank"
            rel="noopener noreferrer"
          >
            Q Broadcasting
          </a>{' '}
          |{' '}
          <a href="/privacy" target="_blank" rel="noopener noreferrer">
            <Translation>
              {(t) =>
                `${t('common.privacy').charAt(0).toUpperCase()}${t(
                  'common.privacy',
                ).slice(1)}`
              }
            </Translation>
          </a>{' '}
          | <a href="mailto:info@qbroadcasting.tv">info@qbroadcasting.tv</a>
        </footer>
      </div>
    );
  }
}

export default Registration;

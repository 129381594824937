import * as React from 'react';
import { Translation } from 'react-i18next';
import ReactCountdown from 'react-countdown';

import { IRegistrationWebinarSettings } from '../../../../interfaces/IRegistration';

import CustomButton from '../CustomButton';

export interface SpeeddateEndedProps {
  settings: IRegistrationWebinarSettings;
  canRejoinSpeeddates: boolean;
  nextSpeeddateRound?: moment.Moment;
  speeddateRoundsRemaining?: number;
  onDissmissNoNewSpeeddateOpponent: () => void;
  onRejoinSpeeddates: () => void;
}

export type SpeeddateEndedState = Record<string, unknown>;

class SpeeddateEnded extends React.Component<
  SpeeddateEndedProps,
  SpeeddateEndedState
> {
  componentDidMount() {
    document.body.style.height = '100%';
    document.documentElement.style.height = '100%';

    const root = document.getElementById('root');
    if (root) root.style.height = '100%';

    document.body.classList.add('host');
  }

  componentWillUnmount() {
    document.body.style.removeProperty('height');
    document.documentElement.style.removeProperty('height');

    const root = document.getElementById('root');
    if (root) root.style.removeProperty('height');

    document.body.classList.remove('host');
  }

  render() {
    const {
      settings: { textColor, backgroundColor, accentColor },
      canRejoinSpeeddates,
      nextSpeeddateRound,
      speeddateRoundsRemaining,
      onDissmissNoNewSpeeddateOpponent,
      onRejoinSpeeddates,
    } = this.props;

    return (
      <div className="h-100 row align-items-center">
        <div className="col" />
        <div className="col-10 col-xl-5">
          <div className="text-center">
            <div className="mb-3 align-items-stretch">
              <div className="card h-100">
                <div className="card-body d-flex flex-column">
                  <h4 className="display-4 mb-3">
                    <i className="fas fa-comment-slash fa-fw text-secondary" />
                  </h4>
                  <h5 className="card-title">
                    <Translation>
                      {(t) => t('webinar.speeddates.noParticipants.title')}
                    </Translation>
                  </h5>
                  <p className="card-text">
                    <Translation>
                      {(t) => t('webinar.speeddates.noParticipants.text')}
                    </Translation>
                    {nextSpeeddateRound &&
                      speeddateRoundsRemaining &&
                      speeddateRoundsRemaining > 0 && (
                        <>
                          <br />
                          <br />
                          <Translation>
                            {(t) => t('webinar.speeddates.nextRoundIn')}
                          </Translation>{' '}
                          <ReactCountdown
                            date={nextSpeeddateRound.toDate()}
                            renderer={({ formatted: { minutes, seconds } }) =>
                              `${minutes}:${seconds}`
                            }
                          />
                        </>
                      )}
                  </p>
                  <div className="row">
                    {speeddateRoundsRemaining === 0 && (
                      <div className="col">
                        <Translation>
                          {(t) => (
                            <CustomButton
                              className="btn-block"
                              icon="arrow-left"
                              text={t('webinar.speeddates.clickToGoBack')}
                              textColor={textColor}
                              backgroundColor={backgroundColor}
                              accentColor={accentColor}
                              onClick={() => onDissmissNoNewSpeeddateOpponent()}
                            />
                          )}
                        </Translation>
                      </div>
                    )}
                    {canRejoinSpeeddates && (
                      <div className="col">
                        <Translation>
                          {(t) => (
                            <CustomButton
                              className="btn-block"
                              icon="users"
                              text={t('webinar.speeddates.clickToWait')}
                              textColor={textColor}
                              backgroundColor={accentColor}
                              accentColor={backgroundColor}
                              onClick={() => onRejoinSpeeddates()}
                            />
                          )}
                        </Translation>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col" />
      </div>
    );
  }
}

export default SpeeddateEnded;

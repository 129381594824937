import React, { useRef, useState, useEffect } from 'react';

export interface ConnectVideoProps {
  onEnded?: () => void;
  onError?: (e: string | Event) => void;
}

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('safari') > -1 && ua.indexOf('chrome') < 0;
};

const mainVideo = '/static/video/speeddate_participant.mp4';

const ConnectVideo: React.FC<ConnectVideoProps> = ({ onEnded, onError }) => {
  const videoParentRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
  const [shouldUseImage, setShouldUseImage] = useState(false);

  useEffect(() => {
    // check if user agent is safari and we have the ref to the container <div />
    if (isSafari() && videoParentRef.current) {
      // obtain reference to the video element
      const player = videoParentRef.current.children[0] as HTMLVideoElement;

      // if the reference to video player has been obtained
      if (player) {
        // set the video attributes using javascript as per the
        // webkit Policy
        player.controls = false;
        // player.playsInline = true;
        player.muted = true;
        player.setAttribute('muted', ''); // leave no stones unturned :)
        player.autoplay = true;

        if (onEnded) player.onended = () => onEnded();
        if (onError) player.onerror = (e) => onError(e);

        // Let's wait for an event loop tick and be async.
        setTimeout(() => {
          // player.play() might return a promise but it's not guaranteed crossbrowser.
          const promise = player.play();
          // let's play safe to ensure that if we do have a promise
          if (promise.catch) {
            promise.catch(() => {
              // if promise fails, hide the video and fallback to <img> tag
              videoParentRef.current.style.display = 'none';
              setShouldUseImage(true);
            });
          }
        }, 0);
      }
    }
  }, [videoParentRef, onEnded, onError]);

  if (onEnded) setTimeout(() => onEnded(), 10500);

  return shouldUseImage ? (
    <img src={mainVideo} alt="Connect video" />
  ) : (
    <div
      style={{ width: '100vw', height: '100vh' }}
      ref={videoParentRef}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: `
        <video
          width="100%"
          height="100%"
          muted
          autoplay
          playsinline
          preload="metadata"
        >
          <source src="${mainVideo}" type="video/mp4" />
        </video>`,
      }}
    />
  );
};

export default ConnectVideo;

import * as React from 'react';
import io from 'socket.io-client';
import moment from 'moment';
import ReactCountdown from 'react-countdown';
import { deviceDetect } from 'react-device-detect';

import config from '../../config';

import EWebinarState from '../../enums/EWebinar';

import Player from './Player';

import '../../styles/embed/style.scss';
import '../../styles/embed/bostik.scss';

import poster from '../../assets/images/bostik-poster.jpg';

const {
  api: { endpoint },
} = config;

export type BostikProps = Record<string, unknown>;

export interface BostikState {
  source?: string;
  webinarState?: EWebinarState;
  start?: string;
}

class Bostik extends React.Component<BostikProps, BostikState> {
  io: SocketIOClient.Socket = io(`${endpoint}`, {
    autoConnect: false,
  });

  constructor(props: BostikProps) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    document.body.classList.add('bostik-body');

    this.io.open();
    this.registerIoEventHandlers();
  }

  componentWillUnmount() {
    document.body.classList.remove('bostik-body');
  }

  registerIoEventHandlers = () => {
    this.io.on('connect', () =>
      this.io.emit('registerBostik', JSON.stringify(deviceDetect())),
    );

    this.io.on(
      'registered',
      (source: string, webinarState: EWebinarState, start: string) =>
        this.setState({ source, webinarState, start }),
    );

    this.io.on('webinarState', (webinarState: EWebinarState) =>
      this.setState({ webinarState }),
    );
  };

  render() {
    const { source, webinarState, start } = this.state;

    return (
      <div className="embed bostik">
        {typeof webinarState !== 'undefined' && source && start ? (
          webinarState === EWebinarState.live ? (
            <div>
              <div>
                <div>
                  <div>
                    <div>
                      <div>
                        <Player
                          source={source}
                          controls={[
                            'play-large',
                            'play',
                            'mute',
                            'volume',
                            'settings',
                            'fullscreen',
                          ]}
                          poster={poster}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : webinarState === EWebinarState.pre ? (
            <div className="container h-100">
              <div className="row h-100 justify-content-center align-items-center">
                <div className="row pt-4">
                  <div className="col-md-12 text-center">
                    <ul className="list-inline">
                      <li className="list-inline-item align-middle mr-4">
                        <h3 className="display-3">
                          <i className="fas fa-clock fa-fw text-muted" />
                        </h3>
                      </li>
                      <li className="list-inline-item align-middle text-left">
                        <h1 className="h2">We will start in...</h1>
                        <span className="text-muted">
                          <ReactCountdown
                            date={moment(start).toDate()}
                            renderer={({
                              days,
                              hours,
                              minutes,
                              seconds,
                              completed,
                            }) => {
                              if (completed)
                                return 'Please wait. We are about to start!';

                              return (
                                <>
                                  {`${days} ${
                                    days === 1 ? 'day' : 'days'
                                  }, ${hours} ${
                                    hours === 1 ? 'hour' : 'hours'
                                  }, ${minutes} ${
                                    minutes === 1 ? 'minute' : 'minutes'
                                  } and ${seconds} ${
                                    seconds === 1 ? 'second' : 'seconds'
                                  }`}
                                  <br />
                                  with{' '}
                                  <span className="font-weight-bold">
                                    Bostik Brand Day 2021
                                  </span>
                                </>
                              );
                            }}
                          />
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="container h-100">
              <div className="row h-100 justify-content-center align-items-center">
                <div className="col-12 text-center">
                  <h3 className="h3">
                    Thank you for joining the Bostik Brand Day event!
                  </h3>
                  More news will follow soon.
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="container h-100">
            <div className="row h-100 justify-content-center align-items-center">
              <div
                className="spinner-border"
                role="status"
                style={{ width: '3rem', height: '3rem' }}
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Bostik;

/* eslint-disable no-param-reassign */
import * as React from 'react';
import { JitsiMeeting } from '@jitsi/react-sdk';
import type { IJitsiMeetExternalApi } from '@jitsi/react-sdk/lib/types';
import { toast } from 'react-toastify';
import { Translation } from 'react-i18next';
import moment from 'moment';

import config from '../../../../config';

import { IPlayerJoinedBreakoutSession } from '../../../../interfaces/IPlayer';
import { IRegistrationWebinarSettings } from '../../../../interfaces/IRegistration';

import BreakoutSessionLoading from '../../../components/BreakoutSessionLoading';
import BreakoutSessionName from './BreakoutSessionName';

import HttpService from '../../../../services/HttpService';
import BreakoutSessionHeader from './BreakoutSessionHeader';

export interface BreakoutSessionProps {
  joinedBreakoutSession: IPlayerJoinedBreakoutSession;
  breakoutSessionCountdown?: moment.Moment;
  settings: IRegistrationWebinarSettings;
  handleLeaveBreakoutSession: () => void;
  handleSetBreakoutSessionEndsIn: (offset?: number) => void;
}

export interface BreakoutSessionState {
  participantName?: string;
  jwt?: string;
  withoutJwt: boolean;
}

class BreakoutSession extends React.Component<
  BreakoutSessionProps,
  BreakoutSessionState
> {
  constructor(props: BreakoutSessionProps) {
    super(props);

    this.state = {
      withoutJwt: false,
    };
  }

  componentDidMount() {
    document.body.style.height = '100%';
    document.documentElement.style.height = '100%';

    const root = document.getElementById('root');
    if (root) root.style.height = '100%';

    const {
      joinedBreakoutSession: { participantName },
    } = this.props;
    const { jwt } = this.state;

    if (participantName && !jwt) this.handleSetParticipantName(participantName);
  }

  componentWillUnmount() {
    document.body.style.removeProperty('height');
    document.documentElement.style.removeProperty('height');

    const root = document.getElementById('root');
    if (root) root.style.removeProperty('height');

    const { handleSetBreakoutSessionEndsIn } = this.props;
    handleSetBreakoutSessionEndsIn(undefined);
  }

  handleAPI = (jitsiApi: IJitsiMeetExternalApi) => {
    // window.jitsiApi = jitsiApi;

    jitsiApi.on('passwordRequired', () => {
      toast.error(
        <Translation>
          {(t) => t('webinar.breakoutSessions.hostNotPresent')}
        </Translation>,
      );
    });

    jitsiApi.on('authenticationRequired', () => {
      toast.error(
        <Translation>
          {(t) => t('webinar.breakoutSessions.hostNotPresent')}
        </Translation>,
      );
    });

    jitsiApi.on('videoConferenceJoined', () => {
      const {
        joinedBreakoutSession: { withChat },
      } = this.props;

      if (withChat) jitsiApi.executeCommand('toggleChat');
    });

    jitsiApi.on('videoConferenceLeft', () => {
      const { handleLeaveBreakoutSession } = this.props;

      handleLeaveBreakoutSession();
    });

    jitsiApi.on('participantKickedOut', ({ kicked: { local } }) => {
      if (local) {
        toast.error(
          <Translation>
            {(t) => t('webinar.breakoutSessions.moderatorKickedYou')}
          </Translation>,
        );

        const { handleLeaveBreakoutSession } = this.props;

        handleLeaveBreakoutSession();
      }
    });
  };

  handleSetParticipantName = async (participantName: string) => {
    try {
      const {
        joinedBreakoutSession: { identifier },
      } = this.props;
      const {
        data,
      }: {
        data:
          | { withHost: false; token: string; endsIn?: number }
          | { withHost: true; endsIn?: number };
      } = await HttpService.post('/breakout-session/participant-token', {
        identifier,
        participantName,
      });

      if (data.withHost === false)
        this.setState({ jwt: data.token, participantName });
      else this.setState({ participantName, withoutJwt: true });

      const { handleSetBreakoutSessionEndsIn } = this.props;

      handleSetBreakoutSessionEndsIn(data.endsIn);
    } catch (e) {
      if (e.response?.status === 404)
        toast.error(
          <Translation>
            {(t) => t('webinar.breakoutSessions.notifications.sessionNotFound')}
          </Translation>,
        );
      else
        toast.error(
          <Translation>
            {(t) => t('common.notifications.somethingWentWrong')}
          </Translation>,
        );
    }
  };

  render() {
    const { participantName, jwt, withoutJwt } = this.state;
    const {
      joinedBreakoutSession: {
        roomName,
        identifier,
        participantName: displayName,
        withChat,
      },
      breakoutSessionCountdown,
      settings,
    } = this.props;

    const {
      jitsi: { domain },
    } = config;

    const toolbarButtons: 'chat'[] = [];
    if (withChat) toolbarButtons.push('chat');

    // const jitsiConfig = {
    //   fileRecordingsEnabled: false,
    //   liveStreamingEnabled: false,
    //   localRecording: {
    //     enabled: false,
    //   },
    //   prejoinPageEnabled: false,
    //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // } as any;

    const name = participantName ?? displayName;

    return (
      <div className="h-100">
        {!participantName && !displayName ? (
          <BreakoutSessionName
            roomName={roomName}
            settings={settings}
            setParticipantName={this.handleSetParticipantName}
          />
        ) : !jwt && !withoutJwt ? (
          <BreakoutSessionLoading name={roomName} />
        ) : (
          <div className="d-flex flex-column h-100">
            {breakoutSessionCountdown && (
              <BreakoutSessionHeader
                className="p-3"
                roomName={roomName}
                endDate={breakoutSessionCountdown}
              />
            )}
            <div className="d-flex flex-grow-1 h-100">
              <JitsiMeeting
                // containerStyle={{ width: '100%', height: '100%' }}
                getIFrameRef={(iframe) => {
                  if (iframe) {
                    iframe.style.height = '100%';
                    iframe.style.width = '100%';
                  }
                }}
                userInfo={
                  name
                    ? {
                        displayName: name,
                        email: '',
                      }
                    : undefined
                }
                onApiReady={(api) => this.handleAPI(api)}
                domain={domain}
                roomName={identifier}
                jwt={jwt}
                configOverwrite={{
                  fileRecordingsEnabled: false,
                  liveStreamingEnabled: false,
                  localRecording: {
                    enabled: false,
                  },
                  prejoinPageEnabled: false,
                  toolbarButtons: [
                    'microphone',
                    'camera',
                    'desktop',
                    'fullscreen',
                    'fodeviceselection',
                    'hangup',
                    'settings',
                    'raisehand',
                    'filmstrip',
                    'shortcuts',
                    'tileview',
                    'videobackgroundblur',
                    'mute-everyone',
                  ],
                }}
                // displayName={participantName ?? displayName}
                // loadingComponent={() => (
                //   <BreakoutSessionLoading name={roomName} />
                // )}
                // onAPILoad={(api) => this.handleAPI(api as IJitsiApi)}
                // config={jitsiConfig}
                // interfaceConfig={{
                //   TOOLBAR_BUTTONS: [
                //     ...toolbarButtons,
                //     'microphone',
                //     'camera',
                //     'desktop',
                //     'fullscreen',
                //     'fodeviceselection',
                //     'hangup',
                //     'settings',
                //     'raisehand',
                //     'filmstrip',
                //     'shortcuts',
                //     'tileview',
                //     'videobackgroundblur',
                //     'mute-everyone',
                //   ],
                // }}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default BreakoutSession;

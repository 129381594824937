import * as React from 'react';
import { Translation } from 'react-i18next';
import ReactPlayer from 'react-player';
import ReactCountdown from 'react-countdown';
import moment from 'moment';

import CustomSpinner from '../../../components/CustomSpinner';
import TranslateString from '../../../components/Translation';

export interface SpeeddateWaitForParticipantProps {
  nextSpeeddateRound?: moment.Moment;
}

class SpeeddateWaitForParticipant extends React.Component<SpeeddateWaitForParticipantProps> {
  componentDidMount() {
    document.body.style.height = '100%';
    document.documentElement.style.height = '100%';

    const root = document.getElementById('root');
    if (root) root.style.height = '100%';
  }

  componentWillUnmount() {
    document.body.style.removeProperty('height');
    document.documentElement.style.removeProperty('height');

    const root = document.getElementById('root');
    if (root) root.style.removeProperty('height');
  }

  render() {
    const { nextSpeeddateRound } = this.props;
    const connectVideo = nextSpeeddateRound?.isBefore();
    return (
      <div
        className={`h-100 row align-items-center${
          connectVideo ? ' bg-black' : ''
        }`}
      >
        {connectVideo ? (
          <ReactPlayer
            width="100%"
            height="100%"
            url={[
              {
                src: '/static/video/speeddate_participant.webm',
                type: 'video/webm',
              },
              {
                src: '/static/video/speeddate_participant.mp4',
                type: 'video/mp4',
              },
            ]}
            playing
            loop
            autoPlay
            mute="true"
          />
        ) : (
          <>
            <div className="col" />
            <div className="col-10 col-xl-5">
              <div className="text-center">
                <div className="mb-3 align-items-stretch">
                  <div className="card h-100">
                    <div className="card-body d-flex flex-column">
                      <h5 className="card-title">
                        {nextSpeeddateRound ? (
                          <Translation>
                            {(t) => (
                              <ReactCountdown
                                date={nextSpeeddateRound.toDate()}
                                renderer={({ seconds, completed }) => {
                                  if (completed)
                                    return `${t(
                                      'webinar.speeddates.searchForParticipant',
                                    )}...`;

                                  return `${t('common.in')
                                    .charAt(0)
                                    .toUpperCase()}${t('common.in').slice(
                                    1,
                                  )} ${seconds} ${t('common.second', {
                                    count: seconds,
                                  })} ${t(
                                    'webinar.speeddates.willStartSpeeddates',
                                  )}`;
                                }}
                              />
                            )}
                          </Translation>
                        ) : (
                          <TranslateString i18nKey="webinar.speeddates.speeddatesAlmostStart" />
                        )}
                      </h5>
                      <CustomSpinner />
                      <p className="card-text">
                        <TranslateString i18nKey="webinar.breakoutSessions.mediaConsent" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col" />
          </>
        )}
      </div>
    );
  }
}

export default SpeeddateWaitForParticipant;

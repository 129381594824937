import * as React from 'react';
import { Translation } from 'react-i18next';

import { IRegistrationWebinarSettings } from '../../../../interfaces/IRegistration';
import { ISurveyQuestion } from '../../../../interfaces/ISurvey';
import ESurveyQuestionType from '../../../../enums/ESurveyQuestion';

import SurveyRank from './SurveyRank';
import SurveyText from './SurveyText';
import CustomButton from '../CustomButton';
import SurveyMultiplechoice from './SurveyMultiplechoice';

export interface SurveyProps {
  surveyQuestions: ISurveyQuestion[];
  settings: IRegistrationWebinarSettings;
  closeButton?: boolean;
  surveyInput?: string;
  withIntroText?: boolean;
  withClose?: boolean;
  onSurveyQuestionAnswer: (id: number, answer?: string | number) => void;
  onSetSurveyInput: (surveyInput: string) => void;
}

const Survey: React.FC<SurveyProps> = ({
  surveyQuestions,
  settings,
  closeButton = false,
  surveyInput,
  withIntroText = true,
  withClose = true,
  onSurveyQuestionAnswer,
  onSetSurveyInput,
}) => {
  const { length: count } = surveyQuestions;
  const question = [...surveyQuestions].shift();

  const contentToArray = (content: string): string[] =>
    JSON.parse(content).filter((i: string) => !!i) as string[];

  const renderSurveyQuestion = (): JSX.Element | null => {
    if (question) {
      const { id, type } = question;

      if (type === ESurveyQuestionType.rank5)
        return (
          <SurveyRank
            question={question.question}
            settings={settings}
            rank={5}
            onRankClick={(rank: number) =>
              onSurveyQuestionAnswer(id, rank.toString())
            }
          />
        );
      if (type === ESurveyQuestionType.rank10)
        return (
          <SurveyRank
            question={question.question}
            settings={settings}
            rank={10}
            onRankClick={(rank: number) =>
              onSurveyQuestionAnswer(id, rank.toString())
            }
          />
        );
      if (type === ESurveyQuestionType.text)
        return (
          <SurveyText
            question={question.question}
            settings={settings}
            surveyInput={surveyInput}
            onSend={(answer?: string) => onSurveyQuestionAnswer(id, answer)}
            onSetSurveyInput={onSetSurveyInput}
          />
        );
      if (type === ESurveyQuestionType.multiplechoice)
        return (
          <SurveyMultiplechoice
            question={question.question}
            settings={settings}
            answers={contentToArray(question.content ?? '[]')}
            onMultiplechoiceClick={(answer: number) =>
              onSurveyQuestionAnswer(id, answer)
            }
            // onSurveyQuestionAnswer(id, answer.toString())
          />
        );
    }

    return null;
  };

  return (
    <div className="col-md-8 mx-auto">
      {withIntroText && (
        <h3 className="h3 mb-3 mb-md-5">
          <Translation>
            {(t) => t('webinar.survey.thankYouForWatching')}
          </Translation>
        </h3>
      )}
      {question ? (
        <>
          {withIntroText && (
            <p className="mb-3 mb-md-5">
              <Translation>
                {(t) => t('webinar.survey.weWouldAskQuestions', { count })}
              </Translation>
            </p>
          )}
          {renderSurveyQuestion()}
          {closeButton && (
            <Translation>
              {(t) => (
                <>
                  <CustomButton
                    className="mt-1"
                    text={t('common.close')}
                    onClick={() => onSurveyQuestionAnswer(0, 0)}
                    textColor={settings.textColor}
                    backgroundColor={settings.accentColor}
                    accentColor={settings.backgroundColor}
                    icon="times"
                  />
                </>
              )}
            </Translation>
          )}
        </>
      ) : (
        <h5 className="h5 mt-3 mb-2 font-italic">
          <Translation>
            {(t) => (
              <>
                {t('webinar.survey.thankYouForYourResponse')}
                {withClose && (
                  <>
                    <br />
                    <CustomButton
                      className="mt-3 mt-md-4"
                      text={t('common.close')}
                      onClick={() => onSurveyQuestionAnswer(0, '')}
                      textColor={settings.textColor}
                      backgroundColor={settings.backgroundColor}
                      accentColor={settings.accentColor}
                    />
                  </>
                )}
              </>
            )}
          </Translation>
        </h5>
      )}
    </div>
  );
};

export default Survey;

import React, { useState } from 'react';

export interface CustomButtonProps {
  text: string;
  textColor?: string;
  backgroundColor?: string;
  accentColor?: string;
  className?: string;
  style?: React.CSSProperties;
  icon?: string;
  disabled?: boolean;
  onClick: () => void;
}

const CustomButton: React.SFC<CustomButtonProps> = ({
  text,
  textColor: color,
  backgroundColor,
  accentColor,
  className,
  style,
  icon,
  disabled = false,
  onClick,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <button
      type="button"
      className={`btn ${className}`}
      style={{
        ...style,
        color,
        backgroundColor: hover ? accentColor : backgroundColor,
      }}
      disabled={disabled}
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      // onFocus={(e) => console.log('focus', e)}
    >
      {icon && <i className={`fas fa-${icon} mr-2`} />}
      {text}
    </button>
  );
};

export default CustomButton;

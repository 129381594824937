import { ITranslation } from '../../interfaces/ITranslation';

const translation: ITranslation = {
  common: {
    webinar: 'webinar',
    date: 'datum',
    loading: 'laden',
    privacy: 'privacy',
    participant: 'Deelnemer',
    participant_plural: 'Deelnemers',
    participate: 'Deelnemen',
    and: 'en',
    in: 'over',
    send: 'Verzenden',
    sent: 'Verzonden',
    skip: 'Overslaan',
    cancel: 'Annuleren',
    close: 'Sluiten',
    yourName: 'Je naam',
    day: 'dag',
    day_plural: 'dagen',
    hour: 'uur',
    hour_plural: 'uren',
    minute: 'minuut',
    minute_plural: 'minuten',
    second: 'seconde',
    second_plural: 'seconden',
    notifications: {
      somethingWentWrong: 'Er is iets fout gegaan. Probeer het later opnieuw',
      serverError: 'De server gaf een foutmelding',
    },
    cookieConsent: {
      mainText:
        'We plaatsen functionele cookies om deze website goed te laten werken. Er worden geen persoonsgegevens opgeslagen in deze cookies.',
      acceptButton: 'Ik snap het',
    },
  },
  registrationForm: {
    information: {
      title: 'Informatie',
      emailInstructions:
        'Na registratie ontvang je een e-mail waarin zich een unieke link bevindt. Door deze link bij de start van het webinar in je webbrowser te openen kun je de videostream bekijken en participeren in het webinar. Uiterlijk een half uur voor aanvang van het webinar ontvang je deze link opnieuw in je mailbox. Een totaal van twee berichten zullen worden verstuurd.',
    },
    userData: {
      title: 'Gegevens',
      firstname: 'Voornaam',
      lastname: 'Achternaam',
      company: 'Organisatie',
      email: 'E-mail',
      phonenumber: 'Telefoonnummer',
      consent: {
        pre: 'Ja, ik heb kennis genomen van het',
        link: 'privacybeleid',
        post:
          'van Q Broadcasting en geef uitdrukkelijk toestemming voor het verwerken van de door mij ingevoerde gegevens om deel te kunnen nemen aan het webinar.',
      },
      submit: 'Registreren',
    },
    registered: {
      title: 'Je bent aangemeld',
      content:
        'Je bent aangemeld voor het webinar. Houd je mail in de gaten, binnenkort ontvang je meer informatie.',
    },
    title: 'Registratieformulier',
    registrationWebinar: 'Registratie Webinar',
    registration: 'Registratie',
    notifications: {
      noValidWebinarId:
        'Je hebt geen valide webinar ID opgegeven. Probeer het opnieuw',
      webinarNotFound:
        'Deze webinar kan niet worden gevonden. Probeer het opnieuw',
      emailAlreadyExists:
        'Er is al een registratie met dit e-mail adres. Je kunt je per e-mail adres maar één keer registreren',
    },
  },
  unsubscribe: {
    unsubscribe: {
      title: 'Afmelden...',
      text: 'Jammer dat je geen berichten meer wil ontvangen',
    },
    unsubscribed: {
      title: 'Afgemeld',
      text: 'Je bent succesvol afgemeld voor alle e-mails van dit webinar',
    },
    clickToUnsubscribe: 'Klik hier om je af te melden',
    somethingWentWrong:
      'Er is iets mis gegaan tijdens het afmelden. Probeer het later nog een keer',
    alreadyUnsubscribed:
      'De registratie kan niet worden gevonden. Je hebt je waarschijnlijk al eerder afgemeld',
  },
  webinar: {
    preWebinar: {
      tooEarly: 'We gaan beginnen over...',
      aMomentOfPatience: 'Nog even geduld. We gaan zo beginnen!',
      untilStartOf: 'met',
    },
    registrationFailed: {
      title: 'Oeps',
      text:
        'Er is iets mis gegaan. Neem contact op met de organisator van het webinar om het probleem op te lossen.',
    },
    alreadyWatching: {
      title: 'Helaas',
      text:
        'Er is al iemand aan het kijken via deze link. Sluit de pagina op het apparaat waar nu gekeken wordt om hier verder te gaan.',
    },
    webinarEnded: {
      title: 'Het webinar is afgelopen',
      text: 'De uitzending is gestopt. Je kunt helaas niet meer meekijken.',
    },
    player: {
      askAQuestion: 'Stel een vraag',
      typeYourQuestion: 'Type hier je vraag',
      questionSent: 'Je vraag is verzonden naar de studio',
      speeddatesStartIn: 'De speeddates beginnen over',
      speeddatesAreInProgress: 'De speeddates zijn bezig.',
      enterNameToParticipate: 'Voer je naam in om deel te nemen',
      pressButtonToParticipate: 'Druk op de knop hieronder om deel te nemen',
      statement: 'Stelling',
      statement_plural: 'Stellingen',
      noOpinion: 'Geen mening',
    },
    survey: {
      thankYouForWatching: 'Bedankt voor het kijken',
      weWouldAskQuestions:
        'Graag zouden we U nog een korte vraag stellen over uw ervaring met het webinar.',
      weWouldAskQuestions_plural:
        'Graag zouden we U nog een aantal korte vragen stellen over uw ervaring met het webinar.',
      thankYouForYourResponse:
        'Dank voor uw response. Er zijn geen vragen meer.',
      typeYourAnswer: 'Type hier uw antwoord',
      chooseOneAnswer: 'Kies één van de antwoorden',
    },
    breakoutSessions: {
      breakoutSession: 'Breakout-sessie',
      breakoutSession_plural: 'Breakout-sessies',
      preBreakoutSession: 'de breakout-sessie',
      preBreakoutSession_plural: 'een van de breakout-sessies',
      breakoutSessionsStarted:
        'De breakout-sessies zijn gestart. Scroll naar beneden om deel te nemen.',
      sessionHasEnded: {
        title: 'Sessie beëindigd',
        text: 'De sessie is beëindigd. Je kunt dit venster nu sluiten.',
      },
      sessionIsEnded: 'Deze sessie is afgelopen',
      participateIn: 'Neem deel aan',
      waitingForHost: 'We wachten nog even op de host',
      enterNameToParticipate: 'Voer je naam in om deel te nemen',
      onlyOnPc:
        'Open deze pagina op een PC of laptop om deel te kunnen nemen aan',
      hostNotPresent:
        'De host van deze sessie is nog niet aanwezig. Probeer het later nogmaals',
      moderatorKickedYou: 'De moderator heeft je verwijderd uit de sessie',
      isLoading: 'wordt geladen',
      mediaConsent:
        'Er wordt toestemming gevraagd om uw camera en microfoon te gebruiken. Deze toestemming is alleen geldig voor deze pagina en zal enkel worden gebruikt om deelname aan de sessie mogelijk te maken.',
      leftSession: 'Je hebt de sessie verlaten',
      endedNotification: {
        pre: 'De breakout-sessie',
        post: 'is beëindigd',
      },
      endOfBreakoutSession: 'Einde van deze sessie',
      host: {
        hostASession: 'Host een breakout-sessie',
        yourNameHelp:
          'Je naam is zichtbaar voor alle deelnemers aan de breakout-sessie',
        yourEmail: 'Je e-mail adres',
        login: 'Inloggen',
        of: 'van',
        subject: 'Onderwerp',
        changeSubjectOfSession: 'Pas het onderwerp van de sessie aan',
        changeSubject: 'Onderwerp aanpassen',
        muteEveryone: 'Iedereen dempen',
      },
      notifications: {
        noValidBreakoutSessionId:
          'Je hebt geen valide breakout-sessie ID opgegeven. Probeer het opnieuw',
        sessionNotFound:
          'Deze breakout-sessie kan niet worden gevonden. Probeer het opnieuw',
        noAccessToSession:
          'Je hebt helaas geen toegang tot deze breakout-sessie. Vraag bij je contactpersoon een (nieuwe) link op',
      },
    },
    speeddates: {
      speeddate: 'Speeddate',
      speeddate_plural: 'Speeddates',
      theSpeeddate: 'De speeddate',
      willStartSpeeddates: 'starten de speeddates',
      speeddatesAlmostStart: 'De speeddates gaan bijna van start',
      searchForParticipant: 'Er wordt een deelnemer gezocht',
      nextRoundIn: 'Volgende ronde over',
      nextRound: 'Volgende ronde',
      lastRound: 'Laatste ronde',
      endOfSpeeddates: 'Einde van de speeddates',
      isGettingStarted: 'wordt gestart',
      clickToGoBack: 'Klik hier om terug te gaan',
      clickToWait: 'Klik hier om opnieuw deel te nemen',
      noParticipants: {
        title: 'Er zijn geen deelnemers',
        text:
          'Helaas is er geen nieuwe deelnemer beschikbaar om een speeddate mee te starten. Door op onderstaande knop te drukken begin je opnieuw aan de speeddates en kan het zijn dat je iemand voor de tweede keer spreekt.',
      },
    },
  },
};

export default translation;

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ReactCountdown from 'react-countdown';
import moment from 'moment';

export interface CountdownProps {
  webinarName: string;
  start: string;
}

const Countdown: React.SFC<CountdownProps> = ({ webinarName, start }) => {
  const { t } = useTranslation();

  return (
    <div className="row pt-4">
      <div className="col-md-12 text-center">
        <ul className="list-inline">
          <li className="list-inline-item align-middle mr-4">
            <h3 className="display-3">
              <i className="fas fa-clock fa-fw text-secondary" />
            </h3>
          </li>
          <li className="list-inline-item align-middle text-left">
            <h1 className="h2">{t('webinar.preWebinar.tooEarly')}</h1>
            <span className="text-muted">
              <ReactCountdown
                date={moment(start).toDate()}
                renderer={({ days, hours, minutes, seconds, completed }) => {
                  if (completed)
                    return t('webinar.preWebinar.aMomentOfPatience');

                  return (
                    <>
                      {`${days} ${t('common.day', {
                        count: days,
                      })}, ${hours} ${t('common.hour', {
                        count: hours,
                      })}, ${minutes} ${t('common.minute', {
                        count: minutes,
                      })} ${t('common.and')} ${seconds} ${t('common.second', {
                        count: seconds,
                      })}`}
                      <br />
                      {`${t('webinar.preWebinar.untilStartOf')} `}
                      <span className="font-weight-bold">{webinarName}</span>
                    </>
                  );
                }}
              />
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Countdown;

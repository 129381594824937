import * as React from 'react';
import { Translation } from 'react-i18next';

import { IRegistrationWebinarSettings } from '../../../../interfaces/IRegistration';

import CustomButton from '../CustomButton';

export interface BreakoutSessionNameProps {
  roomName: string;
  settings: IRegistrationWebinarSettings;
  setParticipantName: (participantName: string) => void;
}

const BreakoutSessionName: React.FC<BreakoutSessionNameProps> = ({
  roomName,
  settings: { textColor, backgroundColor, accentColor },
  setParticipantName,
}) => {
  const [name, setName] = React.useState(
    localStorage.getItem('participantName') || '',
  );

  return (
    <div className="h-100 row align-items-center">
      <div className="col" />
      <div className="col-12 col-xl-4">
        <div className="card mb-3 mb-xl-4">
          <div className="card-body text-center">
            <h4 className="h4 mb-3">
              <Translation>
                {(t) => t('webinar.breakoutSessions.enterNameToParticipate')}
              </Translation>
            </h4>
            <div className="form-group">
              <Translation>
                {(t) => (
                  <input
                    className="form-control text-center"
                    type="text"
                    id="name"
                    placeholder={t('common.yourName')}
                    value={name}
                    onChange={({ currentTarget: { value } }) => setName(value)}
                  />
                )}
              </Translation>
            </div>
            <div className="form-group mb-0">
              <Translation>
                {(t) => (
                  <CustomButton
                    className="btn-block"
                    text={`${t(
                      'webinar.breakoutSessions.participateIn',
                    )} ${roomName}`}
                    textColor={textColor}
                    backgroundColor={backgroundColor}
                    accentColor={accentColor}
                    icon="sign-in-alt"
                    onClick={() => {
                      localStorage.setItem('participantName', name);
                      setParticipantName(name);
                    }}
                    disabled={!name}
                  />
                )}
              </Translation>
            </div>
          </div>
        </div>
      </div>
      <div className="col" />
    </div>
  );
};

export default BreakoutSessionName;

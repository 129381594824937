import * as React from 'react';
import { isMobile } from 'react-device-detect';

import {
  IRegistrationWebinarBreakoutSession,
  IRegistrationWebinarSettings,
} from '../../../../interfaces/IRegistration';
import { IPlayerJoinedBreakoutSession } from '../../../../interfaces/IPlayer';

import BreakoutSessionSelectorCard from './BreakoutSessionSelectorCard';
import BreakoutSessionMobile from '../../../components/BreakoutSessionMobile';

export interface BreakoutSessionSelectorProps {
  className?: string;
  breakoutSessions: IRegistrationWebinarBreakoutSession[];
  settings: IRegistrationWebinarSettings;
  handleJoinBreakoutSession: (values: IPlayerJoinedBreakoutSession) => void;
}

const BreakoutSessionSelector: React.SFC<BreakoutSessionSelectorProps> = ({
  className,
  breakoutSessions,
  settings,
  handleJoinBreakoutSession,
}) => {
  return !isMobile ? (
    <div className={className}>
      <div className="row">
        {breakoutSessions.map((bs, index) => (
          <BreakoutSessionSelectorCard
            breakoutSession={bs}
            settings={settings}
            index={index}
            totalBreakoutSessions={breakoutSessions.length}
            handleJoinBreakoutSession={handleJoinBreakoutSession}
            key={bs.identifier}
          />
        ))}
      </div>
    </div>
  ) : (
    <BreakoutSessionMobile totalBreakoutSessions={breakoutSessions.length} />
  );
};

export default BreakoutSessionSelector;

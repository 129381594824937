import * as React from 'react';

import { ISurveyQuestion } from '../../../interfaces/ISurvey';
import { IRegistrationWebinarSettings } from '../../../interfaces/IRegistration';

import Survey from './Survey';

export interface AfterEndProps {
  survey?: ISurveyQuestion[];
  settings: IRegistrationWebinarSettings;
  surveyInput?: string;
  onSurveyQuestionAnswer: (id: number, answer?: string | number) => void;
  onSetSurveyInput: (surveyInput: string) => void;
}

const AfterEndRabobank: React.SFC<AfterEndProps> = ({
  survey,
  settings,
  surveyInput,
  onSurveyQuestionAnswer,
  onSetSurveyInput,
}) => {
  return (
    <div className="text-center">
      {survey ? (
        <Survey
          surveyQuestions={survey}
          settings={settings}
          surveyInput={surveyInput}
          onSurveyQuestionAnswer={onSurveyQuestionAnswer}
          onSetSurveyInput={onSetSurveyInput}
        />
      ) : (
        <>
          <h3 className="display-3">
            <i className="fas fa-clock fa-fw text-secondary" />
          </h3>
          <h1 className="h2 mb-4">Het webinar is afgelopen</h1>
          <span className="text-muted">
            Heeft u de uitzending op 15 november gemist of wilt u deze nogmaals
            bekijken?
            <br />U vindt de terugkijklink op{' '}
            <a
              href="https://www.rabobank.nl/veilig"
              target="_blank"
              rel="noopener noreferrer"
            >
              rabobank.nl/veilig
            </a>{' '}
            onder het kopje &apos;Actueel&apos;.
            <br />
            <br />
            Bedankt voor uw interesse in ons online fraude event. Blijf alert en
            bij twijfel, bel uw bank!
            <br />
            <br />
            Rabobank
          </span>
        </>
      )}
    </div>
  );
};

export default AfterEndRabobank;

import * as React from 'react';

import logo from '../../assets/images/qbroadcasting_logo.png';

export type PrivacyProps = Record<string, unknown>;

const Privacy: React.SFC<PrivacyProps> = () => {
  return (
    <div className="container">
      <div className="py-5 text-center">
        <img
          className="d-block mx-auto mb-5 webinar-logo"
          src={logo}
          alt="Logo Q Broadcasting"
        />
        <h2>Privacybeleid Q Broadcasting Webinar</h2>
        <p className="font-italic">Versie 1.0</p>
      </div>
      <div className="mb-5">
        <p>Deze pagina is voor het laatst aangepast op 8 oktober 2020.</p>
        <p>
          Wij zijn er van bewust dat u vertrouwen stelt in ons. Wij zien het dan
          ook als onze verantwoordelijkheid om uw privacy te beschermen. Op deze
          pagina laten we u weten welke gegevens we verzamelen als u onze
          website gebruikt, u zich registreert voor een webinar, waarom we deze
          gegevens verzamelen en hoe we hiermee uw gebruikservaring verbeteren.
          Zo snapt u precies hoe wij werken.
        </p>
        <p>
          Dit privacybeleid is van toepassing op de diensten van Q Broadcasting,
          eventueel uitgevoerd in opdracht van een derde partij. U dient zich
          ervan bewust te zijn dat Q Broadcasting niet verantwoordelijk is voor
          het privacybeleid van andere sites en bronnen. Door gebruik te maken
          van deze website geeft u aan het privacy beleid te accepteren.
        </p>
        <p>
          Q Broadcasting respecteert de privacy van alle gebruikers van haar
          site en draagt er zorg voor dat de persoonlijke informatie die u ons
          verschaft vertrouwelijk wordt behandeld.
        </p>
        <h3>Ons gebruik van verzamelde gegevens</h3>
        <h4>Gebruik van onze diensten</h4>
        <p>
          Wanneer u zich aanmeldt voor een van onze diensten vragen we u om
          persoonsgegevens te verstrekken. Deze gegevens worden gebruikt om de
          dienst uit te kunnen voeren. De gegevens worden opgeslagen op eigen
          beveiligde servers van Q Broadcasting of die van een derde partij. Wij
          zullen deze gegevens niet combineren met andere persoonlijke gegevens
          waarover wij beschikken.
        </p>
        <h4>Interactie tijdens het webinar</h4>
        <h5>Vragen</h5>
        <p>
          Tijdens uw deelname aan het webinar heeft U de mogelijkheid om een
          vraag in te sturen via het formulier op de pagina van het
          desbetreffende webinar. Uw vraag zal in combinatie met (indien van
          toepassing) uw registratie of naam worden opgeslagen op eigen
          beveiligde servers van Q Broadcasting of die van een derde partij. De
          vraag die U instuurt wordt mogelijk gebruikt in de uitzending en is op
          dat moment in combinatie met uw naam zichtbaar voor iedereen die
          deelneemt aan het webinar. Het insturen van een vraag is niet
          verplicht en zal ten alle tijden vrijwillig worden geïnitieerd door de
          kijker zelf.
        </p>
        <h5>Stellingen</h5>
        <p>
          Tijdens uw deelname aan het webinar heeft U de mogelijkheid om op de
          pagina van het desbetreffende webinar met een simpele muisklik te
          reageren op een stelling die op dat moment wordt behandeld in de
          uitzending. Uw antwoord op deze stelling zal in combinatie met uw
          registratie worden opgeslagen op eigen beveiligde servers van Q
          Broadcasting of die van een derde partij. Het antwoord dat U instuurt
          wordt nooit in combinatie met uw naam getoond in de uitzending. Enkel
          het resultaat van de antwoorden van alle deelnemers aan het webinar
          worden geanonimiseerd in een grafiek getoond in de uitzending.
          Reageren op een stelling is niet verplicht en kan (indien de
          mogelijkheid om uw antwoord te geven zich voordoet) worden genegeerd
          door op de knop &lsquo;Geen mening&rsquo; te klikken. Er is in dit
          geval geen contact met eigen beveiligde servers van Q Broadcasting of
          die van een derde partij.
        </p>
        <h5>Enquête</h5>
        <p>
          Na afloop van het webinar krijgt U mogelijk een enquête waarin uw
          mening wordt gevraagd over het webinar waaraan U heeft deelgenomen. De
          antwoorden die U geeft zullen in combinatie met uw registratie worden
          opgeslagen op eigen beveiligde servers van Q Broadcasting of die van
          een derde partij en kunnen geanonimiseerd verstrekt worden aan de
          partij die Q Broadcasting de opdracht heeft gegeven tot de uitvoer en
          productie van het webinar teneinde het product te verbeteren. Het
          antwoorden op vragen in deze enquête is niet verplicht en zal ten alle
          tijden vrijwillig worden gedaan door de kijker zelf.
        </p>
        <h5>Rapportage</h5>
        <p>
          Tijdens uw deelname aan het webinar wordt bijgehouden wanneer, hoe
          vaak en hoe lang een gebruiker de pagina van het desbetreffende
          webinar bezoekt en hoe lang deze effectief naar de videostream kijkt.
          Deze gegevens kunnen geanonimiseerd verstrekt worden aan de partij die
          Q Broadcasting de opdracht heeft gegeven tot de uitvoer en productie
          van het webinar teneinde inzicht te krijgen in kijkersgedrag.
        </p>
        <h4>Communicatie</h4>
        <p>
          Wanneer u een e-mail of andere berichten naar ons verzendt, zoals een
          vraag tijdens een actief webinar of uw antwoord op een stelling, is
          het mogelijk dat we die berichten bewaren. Soms vragen wij u naar uw
          persoonlijke gegevens die voor de desbetreffende situatie relevant
          zijn. Dit maakt het mogelijk uw vragen te verwerken en uw verzoeken te
          beantwoorden. De gegevens worden opgeslagen op eigen beveiligde
          servers van Q Broadcasting of die van een derde partij. Wij zullen
          deze gegevens niet combineren met andere persoonlijke gegevens
          waarover wij beschikken.
        </p>
        <h3>Doeleinden</h3>
        <p>
          We verzamelen of gebruiken geen informatie voor andere doeleinden dan
          de doeleinden die worden beschreven in dit privacybeleid tenzij we van
          tevoren uw toestemming hiervoor hebben verkregen.
        </p>
        <h3>Derden</h3>
        <p>
          De informatie wordt niet met derden gedeeld. In enkele gevallen kan de
          informatie intern gedeeld worden. Onze werknemers zijn verplicht om de
          vertrouwelijkheid van uw gegevens te respecteren. Partijen die in
          opdracht van Q Broadcasting uw gegevens verwerken hebben een
          verwerkersovereenkomst getekend waarin is vastgelegd dat (medewerkers
          van) deze partij met de grootste zorg uw persoonsgegevens verwerken.
        </p>
        <h3>Veranderingen</h3>
        <p>
          Deze privacyverklaring is afgestemd op het gebruik van en de
          mogelijkheden op deze site. Eventuele aanpassingen en/of veranderingen
          van deze site, kunnen leiden tot wijzigingen in deze
          privacyverklaring. Het is daarom raadzaam om regelmatig deze
          privacyverklaring te raadplegen.
        </p>
        <h3>Keuzes voor persoonsgegevens</h3>
        <p>
          Wij bieden alle bezoekers de mogelijkheid tot het inzien, veranderen,
          of verwijderen van alle persoonlijke informatie die op moment aan ons
          is verstrekt. Een verzoek tot het inzien, veranderen of verwijderen
          van uw gegevens kan worden gericht aan onderstaand e-mail adres.
        </p>
        <h4>Aanpassen/uitschrijven communicatie</h4>
        <p>
          Als u uw gegevens wilt aanpassen of uzelf uit onze bestanden wilt
          laten halen, kunt u contact met ons op nemen. Zie onderstaande
          contactgegevens. Onderaan iedere geautomatiseerde e-mail vindt u de
          mogelijkheid om u af te melden voor deze specifieke mailing. Wilt u
          zich afmelden voor alle e-mailberichten kunt U daarvoor een verzoek
          tot verwijdering van uw gegevens richten aan onderstaand e-mail adres.
        </p>
        <h4>Vragen en feedback</h4>
        <p>
          We controleren regelmatig of we aan dit privacybeleid voldoen. Als u
          vragen heeft over dit privacybeleid, kunt u contact met ons opnemen:
        </p>
        <p>
          Q Broadcasting
          <br />
          Nijverheidsweg 26-12, 5071 NK Udenhout
          <br />
          Tel: <a href="tel:+31411602425">0411 602 425</a>
          <br />
          E-mail:{' '}
          <a href="mailto:info@qbroadcasting.tv">info@qbroadcasting.tv</a>
        </p>
      </div>
    </div>
  );
};

export default Privacy;

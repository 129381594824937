import * as React from 'react';
import { Translation } from 'react-i18next';

import { ISurveyQuestion } from '../../../interfaces/ISurvey';
import { IRegistrationWebinarSettings } from '../../../interfaces/IRegistration';

import Survey from './Survey';

export interface AfterEndProps {
  survey?: ISurveyQuestion[];
  settings: IRegistrationWebinarSettings;
  surveyInput?: string;
  onSurveyQuestionAnswer: (id: number, answer?: string | number) => void;
  onSetSurveyInput: (surveyInput: string) => void;
}

const AfterEnd: React.SFC<AfterEndProps> = ({
  survey,
  settings,
  surveyInput,
  onSurveyQuestionAnswer,
  onSetSurveyInput,
}) => {
  return (
    <div className="text-center">
      {survey ? (
        <Survey
          surveyQuestions={survey}
          settings={settings}
          surveyInput={surveyInput}
          onSurveyQuestionAnswer={onSurveyQuestionAnswer}
          onSetSurveyInput={onSetSurveyInput}
        />
      ) : (
        <Translation>
          {(t) => (
            <>
              <h3 className="display-3">
                <i className="fas fa-clock fa-fw text-secondary" />
              </h3>
              <h1 className="h2">{t('webinar.webinarEnded.title')}</h1>
              <span className="text-muted">
                {t('webinar.webinarEnded.text')}
              </span>
            </>
          )}
        </Translation>
      )}
    </div>
  );
};

export default AfterEnd;

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCountdown from 'react-countdown';

import { IJitsiParticipant } from '../../../../interfaces/IJitsi';

export interface SpeeddateHeaderProps {
  className?: string;
  participants: IJitsiParticipant[];
  nextSpeeddateRound?: moment.Moment;
  speeddateRoundsRemaining?: number;
}

const SpeeddateHeader: React.FC<SpeeddateHeaderProps> = ({
  className,
  participants,
  nextSpeeddateRound,
  speeddateRoundsRemaining,
}) => {
  const { t } = useTranslation();
  const date = nextSpeeddateRound?.toDate();

  const countdownRef = React.useRef<ReactCountdown>(null);

  useEffect(() => {
    if (countdownRef.current) countdownRef.current.start();
  });

  return (
    <div className={`d-flex flex-row align-items-center ${className}`}>
      <div className="mr-3 flex-fill" style={{ minWidth: 100 }}>
        <h5 className="h5 mb-0">{t('webinar.speeddates.speeddate')}</h5>

        <small className="text-muted">
          {t('common.participant', { count: 2 })}:{' '}
          {participants.length === 0
            ? '-'
            : participants.reduce((p, c, i) => {
                let suffix = '';

                if (participants.length > 1 && i !== 0) {
                  if (i === participants.length - 1)
                    suffix = ` ${t('common.and')} `;
                  else suffix = ', ';
                }

                return `${p}${suffix}${c.displayName}`;
              }, '')}
        </small>
      </div>
      {nextSpeeddateRound && (
        <div className="mr-2 text-right">
          <ReactCountdown
            date={date}
            ref={countdownRef}
            renderer={({ minutes, seconds, completed }) => {
              let roundType = t('webinar.speeddates.nextRound');

              if (speeddateRoundsRemaining === 1)
                roundType = t('webinar.speeddates.lastRound');
              else if (speeddateRoundsRemaining === 0)
                roundType = t('webinar.speeddates.endOfSpeeddates');

              if (completed)
                return (
                  <>
                    <h5 className="h5 mb-0">{roundType}</h5>
                    {speeddateRoundsRemaining !== 0
                      ? `${t('webinar.speeddates.isGettingStarted')}...`
                      : ''}
                  </>
                );

              return (
                <>
                  <h5 className="h5 mb-0">
                    {roundType} {t('common.in')}
                  </h5>
                  {`${
                    minutes > 0
                      ? `${minutes} ${t('common.minute', {
                          count: minutes,
                        })} ${t('common.and')} `
                      : ''
                  }${seconds} ${t('common.second', {
                    count: seconds,
                  })}`}
                </>
              );
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SpeeddateHeader;

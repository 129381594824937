import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import HttpService from '../../services/HttpService';
import TranslateString from '../components/Translation';

interface MatchParams {
  identifier: string;
}

export type UnsubscribeProps = RouteComponentProps<MatchParams>;

export interface UnsubscribeState {
  identifier: string;
  success: boolean;
}

class Unsubscribe extends React.Component<UnsubscribeProps, UnsubscribeState> {
  constructor(props: UnsubscribeProps) {
    super(props);

    const {
      match: {
        params: { identifier },
      },
    } = this.props;

    this.state = {
      identifier,
      success: false,
    };
  }

  handleUnsubscribe = async () => {
    try {
      const { identifier } = this.state;
      const { status } = await HttpService.delete(`/register/${identifier}`);

      if (status !== 204)
        toast.error(
          <TranslateString i18nKey="unsubscribe.somethingWentWrong" />,
        );
      else this.setState({ success: true });
    } catch (e) {
      if (e.response && e.response.status === 404)
        toast.warning(
          <TranslateString i18nKey="unsubscribe.alreadyUnsubscribed" />,
        );
    }
  };

  render() {
    const { success } = this.state;

    return (
      <div className="container h-100 d-flex flex-column align-items-center mt-5">
        <h3 className="display-3">
          <i
            className={`fas fa-${
              success ? 'check-circle' : 'mail-bulk'
            } fa-fw text-${success ? 'success' : 'dark'}`}
          />
        </h3>
        <h1 className="h2">
          <TranslateString
            i18nKey={`unsubscribe.unsubscribe${success ? 'd' : ''}.title`}
          />
        </h1>
        <span className="text-muted">
          <TranslateString
            i18nKey={`unsubscribe.unsubscribe${success ? 'd' : ''}.text`}
          />
        </span>
        {!success && (
          <p className="mt-3">
            <button
              type="button"
              className="btn btn-outline-dark"
              onClick={this.handleUnsubscribe}
            >
              <TranslateString i18nKey="unsubscribe.clickToUnsubscribe" />
            </button>
          </p>
        )}
      </div>
    );
  }
}

export default Unsubscribe;

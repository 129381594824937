import * as React from 'react';
import { Translation } from 'react-i18next';

import { IRegistrationWebinarSettings } from '../../../../interfaces/IRegistration';

import CustomButton from '../CustomButton';

export interface SurveyRankProps {
  question: string;
  settings: IRegistrationWebinarSettings;
  surveyInput?: string;
  onSend: (answer?: string) => void;
  onSetSurveyInput: (surveyInput: string) => void;
}

const SurveyRank: React.SFC<SurveyRankProps> = ({
  question,
  settings: { textColor, backgroundColor, accentColor },
  surveyInput,
  onSend,
  onSetSurveyInput,
}) => {
  return (
    <>
      <h5 className="h5 mb-2 font-italic">{question}</h5>
      <form className="form">
        <div className="row justify-content-center">
          <div className="form-group col-12 col-md-10 mb-4 mb-md-3">
            <Translation>
              {(t) => (
                <textarea
                  className="form-control"
                  placeholder={`${t('webinar.survey.typeYourAnswer')}`}
                  onChange={({ currentTarget: { value } }) =>
                    onSetSurveyInput(value)
                  }
                  value={surveyInput}
                  rows={3}
                  required
                />
              )}
            </Translation>
          </div>
        </div>

        <div className="form-group">
          <Translation>
            {(t) => (
              <CustomButton
                icon={surveyInput ? 'paper-plane' : 'forward'}
                text={surveyInput ? t('common.send') : t('common.skip')}
                textColor={textColor}
                backgroundColor={backgroundColor}
                accentColor={accentColor}
                onClick={() => {
                  onSend(surveyInput);
                  onSetSurveyInput('');
                }}
              />
            )}
          </Translation>
        </div>
      </form>
    </>
  );
};

export default SurveyRank;

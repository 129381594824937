import * as React from 'react';
import { Translation } from 'react-i18next';

export interface BreakoutSessionMobileProps {
  totalBreakoutSessions: number;
}

const BreakoutSessionMobile: React.SFC<BreakoutSessionMobileProps> = ({
  totalBreakoutSessions,
}) => {
  return (
    <div className="text-center">
      <div className="mb-3 align-items-stretch">
        <div className="card h-100">
          <div className="card-body d-flex flex-column">
            <h5 className="card-title">
              <Translation>
                {(t) =>
                  t('webinar.breakoutSessions.breakoutSession', {
                    count: totalBreakoutSessions,
                  })
                }
              </Translation>
            </h5>
            <h4 className="display-4 my-3">
              <i className="fas fa-desktop fa-fw text-secondary mr-3" />
              <i className="fas fa-laptop fa-fw text-secondary" />
            </h4>
            <p className="card-text">
              <Translation>
                {(t) => t('webinar.breakoutSessions.onlyOnPc')}
              </Translation>{' '}
              <Translation>
                {(t) =>
                  t('webinar.breakoutSessions.preBreakoutSession', {
                    count: totalBreakoutSessions,
                  })
                }
              </Translation>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreakoutSessionMobile;

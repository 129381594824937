import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCountdown from 'react-countdown';

export interface BreakoutSessionHeaderProps {
  className?: string;
  roomName: string;
  endDate: moment.Moment;
}

const BreakoutSessionHeader: React.FC<BreakoutSessionHeaderProps> = ({
  className,
  roomName,
  endDate,
}) => {
  const { t } = useTranslation();

  const countdownRef = React.useRef<ReactCountdown>(null);

  useEffect(() => {
    if (countdownRef.current) countdownRef.current.start();
  });

  return (
    <div className={`d-flex flex-row align-items-center ${className}`}>
      <div className="mr-3 flex-fill" style={{ minWidth: 100 }}>
        <h5 className="h5 mb-0">{roomName}</h5>
      </div>
      {endDate && (
        <div className="mr-2 text-right">
          <h5 className="h5 mb-0">
            {t('webinar.breakoutSessions.endOfBreakoutSession')}{' '}
            {t('common.in')}
          </h5>
          <ReactCountdown
            date={endDate.toDate()}
            ref={countdownRef}
            renderer={({ minutes, seconds }) => {
              return `${
                minutes > 0
                  ? `${minutes} ${t('common.minute', {
                      count: minutes,
                    })} ${t('common.and')} `
                  : ''
              }${seconds} ${t('common.second', {
                count: seconds,
              })}`;
            }}
          />
        </div>
      )}
    </div>
  );
};

export default BreakoutSessionHeader;

import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Translation } from 'react-i18next';
import io from 'socket.io-client';
import { deviceDetect } from 'react-device-detect';
// import ReactPlayer from 'react-player';

import config from '../../config';

import { TRegistration } from '../../interfaces/IRegistration';
import { ISurveyQuestion } from '../../interfaces/ISurvey';

import Header from './components/Header';
import CookieConsent from '../components/CookieConsent';
import Survey from './components/Survey';

const {
  api: { endpoint },
} = config;

interface MatchParams {
  identifier: string;
}

export type PlayerProps = RouteComponentProps<MatchParams>;

export interface PlayerState {
  // initialConnect: boolean;
  identifier: string;
  registration?: TRegistration;
  registrationFailed: boolean;
  alreadyWatching: boolean;
  survey?: ISurveyQuestion[];
  surveyInput?: string;
}

class Player extends React.Component<PlayerProps, PlayerState> {
  io: SocketIOClient.Socket = io(`${endpoint}`, {
    autoConnect: false,
  });

  constructor(props: PlayerProps) {
    super(props);

    const {
      match: {
        params: { identifier },
      },
    } = this.props;
    this.state = {
      identifier,
      registrationFailed: false,
      alreadyWatching: false,
    };
  }

  componentDidMount() {
    this.io.open();
    this.io.on('connect', this.handleConnect);
    this.registerIoEventHandlers();
  }

  handleConnect = (): void => {
    const { identifier } = this.state;

    this.io.emit('registerSurvey', identifier, JSON.stringify(deviceDetect()));
  };

  registerIoEventHandlers = (): void => {
    this.io.on('registered', (registration: TRegistration) => {
      this.setState({
        registration: {
          ...registration,
          webinar: {
            ...registration.webinar,
          },
        },
      });

      document.title = registration.webinar.name;
    });

    this.io.on('registrationFailed', () =>
      this.setState({ registrationFailed: true }),
    );

    this.io.on('alreadyWatching', () =>
      this.setState({ alreadyWatching: true }),
    );

    this.io.on('survey', (survey?: ISurveyQuestion[]) =>
      this.setState({ survey }),
    );
  };

  handleSetSurveyInput = (surveyInput: string): void =>
    this.setState({ surveyInput });

  handleSurveyQuestionAnswer = (id: number, answer?: string | number): void => {
    let { survey } = this.state;

    if (survey) {
      if (survey.length === 0 || (id === 0 && answer === 0)) survey = undefined;
      else {
        if (typeof answer !== 'undefined')
          this.io.emit('surveyAnswer', id, answer);

        survey.shift();
      }

      this.setState({ survey });
    }
  };

  render() {
    const {
      registration,
      registrationFailed,
      alreadyWatching,
      survey,
      surveyInput,
    } = this.state;

    return (
      <>
        <CookieConsent />
        {registrationFailed ? (
          <div className="container h-100 d-flex flex-column align-items-center mt-5">
            <h3 className="display-3">
              <i className="fas fa-times-circle fa-fw text-danger" />
            </h3>
            <h1 className="h2">
              <Translation>
                {(t) => t('webinar.registrationFailed.title')}
              </Translation>
              ...
            </h1>
            <span className="text-muted text-center">
              <Translation>
                {(t) => t('webinar.registrationFailed.text')}
              </Translation>
            </span>
          </div>
        ) : alreadyWatching ? (
          <div className="container h-100 d-flex flex-column align-items-center mt-5">
            <h3 className="display-3">
              <i className="fas fa-eye fa-fw text-success" />
            </h3>
            <h1 className="h2">
              <Translation>
                {(t) => t('webinar.alreadyWatching.title')}
              </Translation>
              ...
            </h1>
            <span className="text-muted text-center">
              <Translation>
                {(t) => t('webinar.alreadyWatching.text')}
              </Translation>
            </span>
          </div>
        ) : !registration ? (
          <div className="text-center mt-4">
            <div
              className="spinner-grow"
              style={{ width: '3rem', height: '3rem' }}
              role="status"
            >
              <span className="sr-only">
                <Translation>
                  {(t) =>
                    `${t('common.loading').charAt(0).toUpperCase()}${t(
                      'common.loading',
                    ).slice(1)}`
                  }
                </Translation>
                ...
              </span>
            </div>
          </div>
        ) : (
          <div className="container text-center">
            <Header registration={registration} />

            {survey && (
              <Survey
                surveyQuestions={survey}
                surveyInput={surveyInput}
                settings={registration.webinar.settings}
                withIntroText={false}
                withClose={false}
                onSurveyQuestionAnswer={this.handleSurveyQuestionAnswer}
                onSetSurveyInput={this.handleSetSurveyInput}
              />
            )}
          </div>
        )}
      </>
    );
  }
}

export default Player;

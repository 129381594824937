import * as React from 'react';

import { IRegistrationWebinarSettings } from '../../../../interfaces/IRegistration';

import CustomButton from '../CustomButton';

export interface SurveyRankProps {
  question: string;
  settings: IRegistrationWebinarSettings;
  rank: number;
  onRankClick: (answer: number) => void;
}

const SurveyRank: React.SFC<SurveyRankProps> = ({
  question,
  settings: { textColor, backgroundColor, accentColor },
  rank,
  onRankClick,
}) => {
  const buttons = [];

  for (let i = 1; i <= rank; i += 1) {
    buttons.push(
      <CustomButton
        key={i}
        className="mr-1 mr-md-2 btn-sm"
        text={`${i}`}
        textColor={textColor}
        backgroundColor={backgroundColor}
        accentColor={accentColor}
        onClick={() => onRankClick(i)}
      />,
    );
  }

  return (
    <div className="mb-3">
      <h5 className="h5 mb-2 mb-md-4 font-italic">{question}</h5>
      {buttons}
    </div>
  );
};

export default SurveyRank;

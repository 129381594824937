import * as React from 'react';

import StorageService from '../../../services/StorageService';
import TranslateString from '../../components/Translation';

import logoQBroadcasting from '../../../assets/images/qbroadcasting_logo.png';
import logoGea from '../../../assets/images/logo_gea.png';

export interface FooterProps {
  logo?: string;
  isGEA: boolean;
}

const Footer: React.SFC<FooterProps> = ({ logo, isGEA }) => {
  return (
    <footer className="border-top pt-5 mt-5">
      <div className="row">
        <div className="col-12 mb-4 mb-md-0 col-md-4 text-center text-md-left">
          {logo && (
            <img
              className="mb-4"
              style={{ maxWidth: '85%' }}
              src={isGEA ? logoGea : StorageService.logoUrl(logo)}
              alt="Logo"
            />
          )}
        </div>
        <div className="col-md-4" />
        <div className="col-md-4 mb-4 mb-md-0 text-center text-md-right qb-info">
          <ul className="list-unstyled text-small">
            <li>
              <img
                className="mb-4"
                style={{ maxWidth: '85%' }}
                src={logoQBroadcasting}
                alt="Logo Q Broadcasting"
              />
            </li>
            <li>
              <a
                className="text-muted"
                href="https://www.qbroadcasting.tv/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fas fa-desktop mr-2" />
                www.qbroadcasting.tv
              </a>
            </li>
            <li>
              <a className="text-muted" href="mailto:info@qbroadcasting.tv">
                <i className="fas fa-envelope mr-2" />
                info@qbroadcasting.tv
              </a>
            </li>
            <li>
              <a
                className="text-muted"
                href="/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fas fa-user-shield mr-2" />
                <TranslateString i18nKey="common.privacy" uppercase="first" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import * as React from 'react';
import { Translation } from 'react-i18next';
import Consent from 'react-cookie-consent';

const CookieConsent: React.FC = () => (
  <Consent
    buttonText={
      <Translation>{(t) => t('common.cookieConsent.acceptButton')}</Translation>
    }
    cookieName="qb_consent"
    location="bottom"
    sameSite="strict"
  >
    <Translation>{(t) => t('common.cookieConsent.mainText')}</Translation>
  </Consent>
);

export default CookieConsent;

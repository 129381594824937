import * as React from 'react';

import BreakoutSessionMobile from '../components/BreakoutSessionMobile';

export type HostMobileProps = Record<string, unknown>;

const HostMobile: React.SFC<HostMobileProps> = () => {
  return (
    <div className="h-100 row align-items-center">
      <div className="col" />
      <div className="col-10 col-xl-5">
        <BreakoutSessionMobile totalBreakoutSessions={1} />
      </div>
      <div className="col" />
    </div>
  );
};

export default HostMobile;

import * as React from 'react';
import { Translation } from 'react-i18next';

import { IHostInfo } from '../../interfaces/IHost';

export interface HostLoginFormProps {
  hostInfo: IHostInfo;
  handleStartHostSession: (displayName: string, email: string) => void;
}

const HostLoginForm: React.SFC<HostLoginFormProps> = ({
  hostInfo: { name, description },
  handleStartHostSession,
}) => {
  const [displayName, setDisplayName] = React.useState('');
  const [email, setEmail] = React.useState('');

  return (
    <div className="container d-flex justify-content-center pt-5 text-center">
      <form
        className="form-signin mx-auto w-100"
        onSubmit={(e) => {
          e.preventDefault();

          handleStartHostSession(displayName, email);
        }}
      >
        <div className="form-group">
          <h3 className="h3">
            <Translation>
              {(t) => t('webinar.breakoutSessions.host.hostASession')}
            </Translation>
          </h3>
          <hr />
          <h5 className="h5 mb-4 text-muted">{name}</h5>
          <p>{description}</p>
        </div>
        <hr className="mb-4" />
        <div className="form-group">
          <Translation>
            {(t) => (
              <input
                type="text"
                className="form-control"
                id="name"
                aria-describedby="nameHelp"
                placeholder={t('common.yourName')}
                value={displayName}
                onChange={({ currentTarget: { value } }) =>
                  setDisplayName(value)
                }
                required
              />
            )}
          </Translation>
          <small id="nameHelp" className="form-text text-muted">
            <Translation>
              {(t) => t('webinar.breakoutSessions.host.yourNameHelp')}
            </Translation>
          </small>
        </div>
        <div className="form-group">
          <Translation>
            {(t) => (
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder={t('webinar.breakoutSessions.host.yourEmail')}
                value={email}
                onChange={({ currentTarget: { value } }) => setEmail(value)}
                required
              />
            )}
          </Translation>
        </div>
        <hr />
        <button
          type="submit"
          className="btn btn-primary btn-block"
          disabled={!displayName || !email}
        >
          <Translation>
            {(t) => t('webinar.breakoutSessions.host.login')}
          </Translation>
        </button>
      </form>
    </div>
  );
};

export default HostLoginForm;

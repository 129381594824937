import * as React from 'react';
import { v4 } from 'uuid';
import moment from 'moment';

import { IChatMessage } from '../../../../interfaces/IChat';
import { TRegistration } from '../../../../interfaces/IRegistration';

import Message from './Message';
import InputBar from './InputBar';

export interface ChatProps {
  messages: IChatMessage[];
  connected: boolean;
  registration: TRegistration;
  onSendMessage: (message: IChatMessage) => void;
}

export interface ChatState {
  name?: string;
  messageSent: boolean;
}

class Chat extends React.Component<ChatProps, ChatState> {
  private messagesRef = React.createRef<HTMLDivElement>();

  constructor(props: ChatProps) {
    super(props);

    const { registration } = props;
    let name;

    if (registration.registered) name = registration.name;

    this.state = {
      messageSent: false,
      name,
    };
  }

  componentDidMount() {
    if (this.messagesRef.current)
      this.messagesRef.current.addEventListener('DOMNodeInserted', (_event) => {
        const target = document.getElementById('chat-wrapper');

        if (target)
          (target as HTMLDivElement).scroll({
            top: (target as HTMLDivElement)?.scrollHeight,
            behavior: 'smooth',
          });
      });

    const target = document.getElementById('chat-wrapper');

    if (target)
      (target as HTMLDivElement).scroll({
        top: (target as HTMLDivElement)?.scrollHeight,
      });
  }

  handleSetName = (name: string): void => this.setState({ name });

  handleSendMessage = (message: string): void => {
    const { onSendMessage } = this.props;
    const { name = 'Onbekend', messageSent } = this.state;

    if (!messageSent) this.setState({ messageSent: true });

    onSendMessage({
      id: v4(),
      date: moment().toISOString(),
      message,
      name,
      type: 'outgoing',
    });
  };

  render() {
    const {
      messages,
      connected,
      registration: {
        webinar: {
          settings: { backgroundColor, accentColor, textColor },
        },
      },
    } = this.props;
    const { name, messageSent } = this.state;

    return (
      <div
        id="chat"
        className="mb-5 mb-lg-0"
        style={{ backgroundColor: accentColor }}
      >
        <div className="chat">
          <div id="chat-wrapper">
            <div className="messages p-2" ref={this.messagesRef}>
              {messages.map((message) => (
                <Message
                  message={message}
                  key={message.id}
                  textColor={textColor}
                  backgroundColor={backgroundColor}
                  accentColor={accentColor}
                />
              ))}
            </div>
          </div>
          {!connected && (
            <div className="notconnected-overlay">
              <span>
                <h2 className="h2">
                  <i className="fas fa-unlink" />
                </h2>
                De verbinding is verbroken
                <br />
                We proberen de verbinding te herstellen
              </span>
            </div>
          )}
          {connected && !name && (
            <div className="name-overlay">
              <span>
                Voer je naam in en druk op verbinden om mee te kunnen chatten
                <br />
                <span className="text-left">
                  <h2 className="h2 mt-3">
                    <i className="fas fa-arrow-down" />
                  </h2>
                </span>
              </span>
            </div>
          )}
          {name ? (
            <InputBar
              name="message"
              icon="paper-plane"
              placeholder={`${
                !messageSent ? `Hoi ${name}. ` : ''
              }Typ hier je bericht`}
              textColor={textColor}
              backgroundColor={backgroundColor}
              handleSendText={this.handleSendMessage}
            />
          ) : (
            <InputBar
              name="name"
              buttonText="Verbinden"
              placeholder="Voer hier je naam in"
              canPickEmoji={false}
              textColor={textColor}
              backgroundColor={backgroundColor}
              handleSendText={this.handleSetName}
            />
          )}
        </div>
      </div>
    );
  }
}

export default Chat;

import * as React from 'react';
import { Translation } from 'react-i18next';

import CustomSpinner from './CustomSpinner';

export interface BreakoutSessionLoadingProps {
  name: string;
}

const BreakoutSessionLoading: React.SFC<BreakoutSessionLoadingProps> = ({
  name,
}) => {
  return (
    <div className="h-100 row align-items-center">
      <div className="col" />
      <div className="col-10 col-xl-5">
        <div className="text-center">
          <div className="mb-3 align-items-stretch">
            <div className="card h-100">
              <div className="card-body d-flex flex-column">
                <h5 className="card-title">
                  <span className="font-weight-bold">{name}</span>{' '}
                  <Translation>
                    {(t) => t('webinar.breakoutSessions.isLoading')}
                  </Translation>
                </h5>
                <CustomSpinner />
                <p className="card-text">
                  <Translation>
                    {(t) => t('webinar.breakoutSessions.mediaConsent')}
                  </Translation>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col" />
    </div>
  );
};

export default BreakoutSessionLoading;

/* eslint-disable no-param-reassign */
import * as React from 'react';
import { Translation } from 'react-i18next';
import io from 'socket.io-client';
import { JitsiMeeting } from '@jitsi/react-sdk';
import type { IJitsiMeetExternalApi } from '@jitsi/react-sdk/lib/types';
import jwtDecode from 'jwt-decode';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { toast } from 'react-toastify';

import config from '../../../config';

import History from '../../../utilities/History';

import { IHostSession, IHostInfo, IHostJwt } from '../../../interfaces/IHost';
import { IJitsiParticipant } from '../../../interfaces/IJitsi';

import HostSessionHeader from './HostSessionHeader';

const {
  api: { endpoint },
  jitsi: { domain },
} = config;

const EndSessionSwal = withReactContent(Swal);

interface HostSessionProps {
  hostSession: IHostSession;
  hostInfo: IHostInfo;
  hostJwt: string;
}

export interface HostSessionState {
  hostJwtContent: IHostJwt;
  jitsiApi?: IJitsiMeetExternalApi;
  videoConferenceJoined: boolean;
  participants: IJitsiParticipant[];
  customSubject?: string;
  redirectToEndedPageAfterHangup: boolean;
}

class HostSession extends React.Component<HostSessionProps, HostSessionState> {
  io: SocketIOClient.Socket = io(`${endpoint}`, {
    autoConnect: false,
  });

  constructor(props: HostSessionProps) {
    super(props);

    const { hostJwt } = this.props;

    this.state = {
      hostJwtContent: jwtDecode(hostJwt) as IHostJwt,
      videoConferenceJoined: false,
      participants: [],
      redirectToEndedPageAfterHangup: false,
    };
  }

  componentDidMount() {
    document.body.style.height = '100%';
    document.documentElement.style.height = '100%';

    const root = document.getElementById('root');
    if (root) root.style.height = '100%';

    const {
      hostInfo: { name },
    } = this.props;
    document.title = `Host: ${name}`;
  }

  componentWillUnmount() {
    document.body.style.removeProperty('height');
    document.documentElement.style.removeProperty('height');

    const root = document.getElementById('root');
    if (root) root.style.removeProperty('height');
  }

  handleConnect = (): void => {
    const {
      hostSession: { identifier, hostToken },
    } = this.props;
    const {
      hostJwtContent: {
        context: {
          user: { name, email },
        },
      },
    } = this.state;

    this.io.emit('registerHost', { identifier, hostToken, name, email });
  };

  handleDisconnect = (): void => {
    //
  };

  registerIoEventHandlers = (): void => {
    // this.io.on('hostRegistered', () => {
    //   console.log('HOST REGISTERED');
    // });
    this.io.on('sessionEnded', () => this.hangupSession(false));
  };

  handleAPI = (jitsiApi: IJitsiMeetExternalApi) => {
    const {
      hostInfo: { name },
    } = this.props;

    this.setState({ jitsiApi });

    jitsiApi.on('videoConferenceJoined', () => {
      this.setState({ videoConferenceJoined: true });

      this.io.open();
      this.io.on('connect', this.handleConnect);
      this.io.on('disconnect', this.handleDisconnect);
      this.registerIoEventHandlers();

      this.updateParticipants();

      jitsiApi.executeCommand('toggleChat');
    });
    jitsiApi.on('videoConferenceLeft', () => {
      const { redirectToEndedPageAfterHangup } = this.state;

      if (redirectToEndedPageAfterHangup) History.push('/host/ended');
      else {
        toast.info(
          <Translation>
            {(t) => t('webinar.breakoutSessions.leftSession')}
          </Translation>,
        );
        window.location.reload();
      }
    });
    jitsiApi.on('participantJoined', this.updateParticipants);
    jitsiApi.on('participantKickedOut', this.updateParticipants);
    jitsiApi.on('participantLeft', this.updateParticipants);
    jitsiApi.on('displayNameChange', this.updateParticipants);
    jitsiApi.on('avatarChanged', this.updateParticipants);
    jitsiApi.on('subjectChange', ({ subject }) => {
      if (subject === '')
        setTimeout(() => jitsiApi.executeCommand('subject', name), 1000);
    });
  };

  updateParticipants = () => {
    const { jitsiApi } = this.state;

    if (jitsiApi) {
      const participants = jitsiApi.getParticipantsInfo();

      this.setState({ participants: participants as IJitsiParticipant[] });
    }
  };

  handleSetSubject = (subject: string) => {
    const { jitsiApi } = this.state;

    if (jitsiApi) jitsiApi.executeCommand('subject', subject);
    this.setState({ customSubject: subject });
  };

  handleMuteEveryone = () => {
    const { jitsiApi } = this.state;

    if (jitsiApi) jitsiApi.executeCommand('muteEveryone');
  };

  handleEndSession = () => {
    EndSessionSwal.fire({
      title: <p>Weet je zeker dat je de sessie wil beëindigen?</p>,
      text:
        'Als je de sessie beëindigt zal iedereen de breakout-sessie verlaten en terugkeren naar het hoofdscherm. Het is niet mogelijk om de sessie weer te starten.',
      showConfirmButton: true,
      confirmButtonText: 'Ja! Beëindig de sessie',
      showDenyButton: true,
      denyButtonText: 'Nee! Dat is niet de bedoeling',
      icon: 'warning',
      heightAuto: false,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        this.io.emit('endBreakoutSession');

        this.hangupSession();
      }
      return null;
    });
  };

  hangupSession = (redirectToEndedPageAfterHangup = true) =>
    this.setState({ redirectToEndedPageAfterHangup }, () => {
      const { jitsiApi } = this.state;

      if (jitsiApi) jitsiApi.executeCommand('hangup');
    });

  render() {
    const { hostInfo, hostJwt } = this.props;
    const {
      hostJwtContent: { room },
      videoConferenceJoined,
      participants,
      customSubject,
    } = this.state;

    return (
      <div className="d-flex flex-column h-100">
        <HostSessionHeader
          className="p-3"
          hostInfo={hostInfo}
          videoConferenceJoined={videoConferenceJoined}
          participants={participants}
          customSubject={customSubject}
          handleSetSubject={this.handleSetSubject}
          handleMuteEveryone={this.handleMuteEveryone}
          handleEndSession={this.handleEndSession}
        />
        <div className="d-flex flex-grow-1 h-100">
          <JitsiMeeting
            getIFrameRef={(iframe) => {
              if (iframe) {
                iframe.style.height = '100%';
                iframe.style.width = '100%';
              }
            }}
            onApiReady={(api) => this.handleAPI(api)}
            domain={domain}
            roomName={room}
            jwt={hostJwt}
            configOverwrite={{
              fileRecordingsEnabled: false,
              liveStreamingEnabled: false,
              localRecording: {
                enabled: false,
              },
              prejoinPageEnabled: false,
              toolbarButtons: [
                'microphone',
                'camera',
                'desktop',
                'fullscreen',
                'fodeviceselection',
                'hangup',
                'settings',
                'raisehand',
                'filmstrip',
                'shortcuts',
                'tileview',
                'videobackgroundblur',
                'mute-everyone',
              ],
            }}
          />
        </div>
      </div>
    );
  }
}

export default HostSession;

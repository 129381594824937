import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import moment from 'moment';

import i18n from './i18n';

import Registration from './content/registration';
import Unsubscribe from './content/unsubscribe';
import Privacy from './content/privacy';
import Player from './content/player';
import Survey from './content/player/survey';
import HostSessionEnded from './content/host/HostSessionEnded';
import Host from './content/host';
import TestBreakoutSession from './content/test/TestBreakoutSession';
import LanguageButton from './content/components/LanguageButton';

export type IAppProps = Record<string, unknown>;

export default class App extends React.Component<IAppProps> {
  componentDidMount() {
    i18n.on('languageChanged', () => {
      moment.locale(i18n.language);
      this.forceUpdate();
    });
  }

  componentWillUnmount() {
    i18n.off('languageChanged');
  }

  public render() {
    return (
      <>
        <ToastContainer />
        <LanguageButton />
        <Switch>
          <Route path="/register/:registrationKey" component={Registration} />
          <Route path="/unsubscribe/:identifier" component={Unsubscribe} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/player/:identifier" component={Player} />
          <Route path="/survey/:identifier" component={Survey} />
          <Route path="/host/ended" component={HostSessionEnded} />
          <Route path="/host/:identifier/:hostToken" component={Host} />
          <Route path="/test-session/ended" component={HostSessionEnded} />
          <Route path="/test-session" component={TestBreakoutSession} />
          <Route
            render={() => {
              window.location.href = 'https://www.qbroadcasting.tv/';
              return null;
            }}
          />
        </Switch>
      </>
    );
  }
}

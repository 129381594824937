import * as React from 'react';
// import { Translation } from 'react-i18next';
import Jitsi from 'react-jitsi';
import jwtDecode from 'jwt-decode';
// import ReactPlayer from 'react-player';

import config from '../../../../config';

import { IJitsiApi, IJitsiParticipant } from '../../../../interfaces/IJitsi';

import ConnectVideo from './ConnectVideo';

// import BreakoutSessionLoading from '../../../components/BreakoutSessionLoading';

import SpeeddateHeader from './SpeeddateHeader';

export interface SpeeddateProps {
  token: string;
  nextSpeeddateRound?: moment.Moment;
  speeddateRoundsRemaining?: number;
  onLeaveSpeeddate: () => void;
}

export interface SpeeddateState {
  // videoConferenceJoined: boolean;
  jitsiApi?: IJitsiApi;
  participants: IJitsiParticipant[];
  connectVideo: boolean;
}

const {
  jitsi: { domain },
} = config;

class Speeddate extends React.Component<SpeeddateProps, SpeeddateState> {
  constructor(props: SpeeddateProps) {
    super(props);

    this.state = {
      // videoConferenceJoined: false,
      participants: [],
      connectVideo: true,
    };
  }

  componentDidMount() {
    document.body.style.height = '100%';
    document.documentElement.style.height = '100%';

    const root = document.getElementById('root');
    if (root) root.style.height = '100%';
  }

  // shouldComponentUpdate(nextProps: SpeeddateProps, nextState: SpeeddateState) {
  //   const { connectVideo } = nextState;
  //   const { token: currentToken } = this.props;
  //   const { token: nextToken } = nextProps;

  //   if (currentToken !== nextToken) return true;

  //   if (nextProps !== this.props) return true;

  //   if (!connectVideo) return true;

  //   return true;
  // }

  componentDidUpdate(prevProps: SpeeddateProps, _prevState: SpeeddateState) {
    const { token: prevToken } = prevProps;
    const { token: currentToken } = this.props;

    if (prevToken !== currentToken) this.startConnectVideo();

    // const video = document.querySelector('#jahoor video');

    // if (video) (video as HTMLVideoElement).play();
  }

  componentWillUnmount() {
    document.body.style.removeProperty('height');
    document.documentElement.style.removeProperty('height');

    const root = document.getElementById('root');
    if (root) root.style.removeProperty('height');
  }

  startConnectVideo = () =>
    this.setState({
      connectVideo: true,
    });

  handleAPI = (jitsiApi: IJitsiApi) => {
    this.setState({ jitsiApi });

    // jitsiApi.addEventListener('videoConferenceJoined', () =>
    //   this.setState({ videoConferenceJoined: true }),
    // );
    jitsiApi.addEventListener('videoConferenceLeft', () => {
      const { onLeaveSpeeddate } = this.props;

      onLeaveSpeeddate();
    });
    jitsiApi.addEventListener('participantJoined', this.updateParticipants);
    jitsiApi.addEventListener('participantKickedOut', this.updateParticipants);
    jitsiApi.addEventListener('participantLeft', this.updateParticipants);
    jitsiApi.addEventListener('displayNameChange', this.updateParticipants);
    jitsiApi.addEventListener('avatarChanged', this.updateParticipants);

    jitsiApi.addEventListener('cameraError', () => {
      // Camera Error
    });
    jitsiApi.addEventListener('micError', () => {
      // Mic Error
    });

    jitsiApi.executeCommand('subject', 'Speeddate');
  };

  updateParticipants = () => {
    const { jitsiApi } = this.state;

    if (jitsiApi) {
      const participants = jitsiApi.getParticipantsInfo();

      this.setState({ participants });
    }
  };

  checkVideoEnded = () => {
    const { connectVideo } = this.state;

    if (connectVideo)
      this.setState({ connectVideo: false }, () => this.toggleMedia());
  };

  toggleMedia = () => {
    const { jitsiApi } = this.state;

    if (jitsiApi) {
      jitsiApi.executeCommand('toggleAudio');
      jitsiApi.executeCommand('toggleVideo');
    }
  };

  render() {
    const { token, nextSpeeddateRound, speeddateRoundsRemaining } = this.props;
    const { participants, connectVideo } = this.state;

    const {
      room: roomName,
      context: {
        user: { name: displayName },
      },
    } = jwtDecode(token) as {
      room: string;
      context: { user: { name: string } };
    };

    return (
      <div className="d-flex flex-column h-100">
        {connectVideo && (
          <div className="bg-black" style={{ position: 'fixed' }}>
            {/* <ReactPlayer
              id="jahoor"
              width="100vw"
              height="100vh"
              url={[
                {
                  src: '/static/video/speeddate_participant.webm',
                  type: 'video/webm',
                },
                {
                  src: '/static/video/speeddate_participant.mp4',
                  type: 'video/mp4',
                },
              ]}
              onEnded={() => this.checkVideoEnded()}
              // onError={() => this.checkVideoEnded()}
              playing
              autoPlay
              mute="true"
              playsinline
            /> */}
            <ConnectVideo onEnded={() => this.checkVideoEnded()} />
          </div>
        )}
        <SpeeddateHeader
          className="p-3"
          participants={participants}
          nextSpeeddateRound={nextSpeeddateRound}
          speeddateRoundsRemaining={speeddateRoundsRemaining}
        />
        <div className="d-flex flex-grow-1 h-100">
          <Jitsi
            key={roomName}
            containerStyle={{ width: '100%', height: '100%' }}
            domain={domain}
            jwt={token}
            roomName={roomName}
            displayName={displayName}
            // loadingComponent={() => (
            //   <Translation>
            //     {(t) => (
            //       <BreakoutSessionLoading
            //         name={t('webinar.speeddates.theSpeeddate')}
            //       />
            //     )}
            //   </Translation>
            // )}
            onAPILoad={(api) => this.handleAPI(api as IJitsiApi)}
            config={{
              fileRecordingsEnabled: false,
              liveStreamingEnabled: false,
              localRecording: {
                enabled: false,
              },
              startWithAudioMuted: true,
              startWithVideoMuted: true,
            }}
          />
        </div>
      </div>
    );
  }
}

export default Speeddate;

import * as React from 'react';
import { Translation } from 'react-i18next';

export interface CustomSpinnerProps {
  className?: string;
}

const CustomSpinner: React.SFC<CustomSpinnerProps> = ({ className }) => {
  return (
    <div className={`text-center ${className}`}>
      <div
        className="spinner-grow"
        style={{ width: '3rem', height: '3rem' }}
        role="status"
      >
        <span className="sr-only">
          <Translation>
            {(t) =>
              `${t('common.loading').charAt(0).toUpperCase()}${t(
                'common.loading',
              ).slice(1)}`
            }
          </Translation>
          ...
        </span>
      </div>
    </div>
  );
};

export default CustomSpinner;

import * as React from 'react';
import moment from 'moment';

import { IChatMessage } from '../../../../interfaces/IChat';

export interface MessageProps {
  message: IChatMessage;
  textColor: string;
  backgroundColor: string;
  accentColor: string;
}

const Message: React.FC<MessageProps> = ({
  message: { name, date, message, type },
  textColor: color,
  backgroundColor,
  accentColor,
}) => (
  <div className="message">
    <div className={type}>
      <div
        className="content"
        style={{
          color,
          backgroundColor: type === 'outgoing' ? accentColor : backgroundColor,
          border:
            type === 'outgoing'
              ? `2px solid ${backgroundColor}`
              : `0px solid ${backgroundColor}`,
        }}
      >
        <p className="name">
          {name}{' '}
          <small className="font-italic">{moment(date).format('HH:mm')}</small>
        </p>
        {message}
      </div>
    </div>
  </div>
);

export default Message;

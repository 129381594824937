import * as React from 'react';
import Jitsi from 'react-jitsi';
import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';

import config from '../../config';

import { IJitsiApi } from '../../interfaces/IJitsi';

import HttpService from '../../services/HttpService';
import History from '../../utilities/History';

import BreakoutSessionLoading from '../components/BreakoutSessionLoading';

import micError from '../../assets/images/mic-error.png';

export type TestBreakoutSessionProps = Record<string, unknown>;

export interface TestBreakoutSessionState {
  token?: string;
  info?: { room: string };
}

class TestBreakoutSession extends React.Component<
  TestBreakoutSessionProps,
  TestBreakoutSessionState
> {
  constructor(props: TestBreakoutSessionProps) {
    super(props);

    this.state = { token: undefined };
  }

  componentDidMount() {
    document.body.style.height = '100%';
    document.documentElement.style.height = '100%';

    const root = document.getElementById('root');
    if (root) root.style.height = '100%';
  }

  componentWillUnmount() {
    document.body.style.removeProperty('height');
    document.documentElement.style.removeProperty('height');

    const root = document.getElementById('root');
    if (root) root.style.removeProperty('height');
  }

  getJwt = async () => {
    try {
      const {
        data: { token },
      }: { data: { token: string } } = await HttpService.get(
        '/breakout-session/test-token',
      );

      this.setState({ token, info: jwtDecode(token) as { room: string } });
    } catch (e) {
      toast.error('Er is iets mis gegaan...');
    }
  };

  handleAPI = (jitsiApi: IJitsiApi) => {
    jitsiApi.addEventListener('videoConferenceJoined', () =>
      jitsiApi.executeCommand('toggleChat'),
    );

    jitsiApi.addEventListener('videoConferenceLeft', () =>
      History.push('/test-session/ended'),
    );
  };

  render() {
    const { token, info } = this.state;
    const {
      jitsi: { domain },
    } = config;

    return token && info ? (
      <Jitsi
        containerStyle={{ width: '100%', height: '100%' }}
        domain={domain}
        jwt={token}
        roomName={info.room}
        displayName="Testgebruiker"
        loadingComponent={() => <BreakoutSessionLoading name="Test-sessie" />}
        onAPILoad={(api) => this.handleAPI(api as IJitsiApi)}
        config={{
          fileRecordingsEnabled: false,
          liveStreamingEnabled: false,
          localRecording: {
            enabled: false,
          },
        }}
      />
    ) : (
      <div className="h-100 row align-items-center">
        <div className="col" />
        <div className="col-10 col-xl-5">
          <div className="text-center">
            <div className="mb-3 align-items-stretch">
              <div className="card h-100">
                <div className="card-body d-flex flex-column">
                  <h4 className="display-4 mb-3">
                    <i className="fas fa-desktop fa-fw" />
                  </h4>
                  <h5 className="card-title">Test-sessie</h5>
                  <p className="card-text">
                    Met deze link kunt u testen of het mogelijk is om deel te
                    nemen aan speeddates en breakout sessies tijdens het
                    webinar. Voor de beste ervaring gebruik uw privélaptop, zo
                    omzeilt u zeker eventuele problemen.
                  </p>
                  <p className="card-text">
                    Voor het betreden van deze sessies zal het systeem vragen om
                    uw camera- en microfoon toegang. U kunt de camera en
                    microfoon van uw computer maar op 1 enkele plek tegelijk
                    gebruiken; zorg er dus voor dat er geen ander (conference)
                    programma openstaat waarmee uw camera en microfoon actief
                    is. Bijvoorbeeld in Zoom, FaceTime, Skype of Teams.
                  </p>
                  <p className="card-text">
                    Open de link bij voorkeur met Google Chrome of Safari en
                    zonder een actieve VPN verbinding.
                  </p>
                  <button
                    type="button"
                    className="btn btn-success mb-4 mt-1"
                    onClick={() => this.getJwt()}
                  >
                    Start de test-sessie
                  </button>
                  <span className="border-top mb-4" />
                  <p className="font-italic text-left text-muted">
                    <img
                      className="w-50 mr-2"
                      style={{ float: 'left' }}
                      src={micError}
                      alt="Toestemming voor microfoon kan niet worden weergeven"
                    />
                    Indien je deze melding ziet kan de toestemming voor camera
                    en microfoon niet worden verkregen. Check of deze optie niet
                    geblokkeerd wordt door je browser of, indien je een computer
                    van de zaak hebt, het bedrijfsbeleid dit tegenhoudt.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col" />
      </div>
    );
  }
}

export default TestBreakoutSession;

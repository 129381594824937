import config from '../config';

const { endpoint } = config.api;

const logoUrl = (filename: string): string =>
  `${endpoint}/static/logo/${filename}`;

export default {
  logoUrl,
};

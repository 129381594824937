import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Translation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';

import { IHostSession, IHostInfo } from '../../interfaces/IHost';

import HttpService from '../../services/HttpService';

import CustomSpinner from '../components/CustomSpinner';
import CookieConsent from '../components/CookieConsent';

import HostMobile from './HostMobile';
import HostSession from './HostSession';
import HostLoginForm from './HostLoginForm';

interface MatchParams {
  identifier: string;
  hostToken: string;
}

export type HostProps = RouteComponentProps<MatchParams>;

export interface HostState {
  hostSession: IHostSession;
  hostInfo?: IHostInfo;
  hostJwt?: string;
}

class Host extends React.Component<HostProps, HostState> {
  constructor(props: HostProps) {
    super(props);

    const {
      match: {
        params: { identifier, hostToken },
      },
    } = this.props;

    this.state = {
      hostSession: {
        identifier,
        hostToken,
      },
    };
  }

  componentDidMount() {
    document.body.style.height = '100%';
    document.documentElement.style.height = '100%';

    const root = document.getElementById('root');
    if (root) root.style.height = '100%';

    document.body.classList.add('host');

    this.getBreakoutSessionName();
  }

  componentWillUnmount() {
    document.body.style.removeProperty('height');
    document.documentElement.style.removeProperty('height');

    const root = document.getElementById('root');
    if (root) root.style.removeProperty('height');

    document.body.classList.remove('host');
  }

  getBreakoutSessionName = async () => {
    try {
      const {
        hostSession: { identifier },
      } = this.state;

      const { data: hostInfo }: { data: IHostInfo } = await HttpService.get(
        '/breakout-session/hostinfo',
        {
          params: { identifier },
        },
      );

      this.setState({ hostInfo });

      // this.handleStartHostSession('Rein van Haaren', 'rein@reinvanhaaren.nl');
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400)
          toast.warn(
            <Translation>
              {(t) =>
                t(
                  'webinar.breakoutSessions.notifications.noValidBreakoutSessionId',
                )
              }
            </Translation>,
          );
        else if (e.response.status === 404)
          toast.error(
            <Translation>
              {(t) =>
                t('webinar.breakoutSessions.notifications.sessionNotFound')
              }
            </Translation>,
          );
        else
          toast.error(
            <Translation>
              {(t) => t('common.notifications.somethingWentWrong')}
            </Translation>,
          );
      } else
        toast.error(
          <Translation>
            {(t) => t('common.notifications.somethingWentWrong')}
          </Translation>,
        );
    }
  };

  handleStartHostSession = async (displayName: string, email: string) => {
    try {
      const {
        hostSession: { identifier, hostToken },
      } = this.state;

      const {
        data: { token },
      }: { data: { token: string } } = await HttpService.post(
        `/breakout-session/host-token`,
        {
          identifier,
          hostToken,
          displayName,
          email,
        },
      );

      this.setState({ hostJwt: token });
    } catch (err) {
      if (err.response) {
        if (err.response.status === 400) {
          if (err.response.data?.message) toast.warn(err.response.data.message);
          else
            toast.warn(
              <Translation>
                {(t) => t('common.notifications.somethingWentWrong')}
              </Translation>,
            );
        } else if (err.response.status === 403)
          toast.error(
            <Translation>
              {(t) =>
                t('webinar.breakoutSessions.notifications.noAccessToSession')
              }
            </Translation>,
          );
        else if (err.response.status === 404)
          toast.error(
            <Translation>
              {(t) =>
                t('webinar.breakoutSessions.notifications.sessionNotFound')
              }
            </Translation>,
          );
        else
          toast.error(
            <Translation>
              {(t) => t('common.notifications.somethingWentWrong')}
            </Translation>,
          );
      } else
        toast.error(
          <Translation>
            {(t) => t('common.notifications.somethingWentWrong')}
          </Translation>,
        );
    }
  };

  render() {
    const { hostSession, hostInfo, hostJwt } = this.state;

    return isMobile ? (
      <HostMobile />
    ) : !hostInfo ? (
      <CustomSpinner className="mt-5" />
    ) : (
      <>
        <CookieConsent />
        {hostJwt ? (
          <HostSession
            hostSession={hostSession}
            hostInfo={hostInfo}
            hostJwt={hostJwt}
          />
        ) : (
          <HostLoginForm
            hostInfo={hostInfo}
            handleStartHostSession={this.handleStartHostSession}
          />
        )}
      </>
    );
  }
}

export default Host;

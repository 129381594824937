import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Translation } from 'react-i18next';
import io from 'socket.io-client';
import moment from 'moment';
import { Howl } from 'howler';
import { toast } from 'react-toastify';
import { deviceDetect } from 'react-device-detect';
// import ReactPlayer from 'react-player';

import config from '../../config';
import i18n from '../../i18n';

import {
  IRegistrationWebinarBreakoutSession,
  IRegistrationWebinarStream,
  TRegistration,
} from '../../interfaces/IRegistration';
import { IStatement } from '../../interfaces/IStatement';
import { IChatMessage } from '../../interfaces/IChat';
import { ISurveyQuestion } from '../../interfaces/ISurvey';

import Header from './components/Header';
import Countdown from './components/Countdown';
import VideoPlayer from './components/VideoPlayer';
import Footer from './components/Footer';
import AfterEnd from './components/AfterEnd';
import AfterEndRabobank from './components/AfterEndRabobank';
import DivWithHTML from './components/DivWithHTML';
import EWebinarState from '../../enums/EWebinar';
import BreakoutSessionSelector from './components/BreakoutSessionSelector';
import { IPlayerJoinedBreakoutSession } from '../../interfaces/IPlayer';
import BreakoutSession from './components/BreakoutSession';
import PlyrStyle from './components/PlyrStyle';
import PlayerComponent from '../components/PlayerComponent';
import Speeddate from './components/Speeddate';
import SpeeddateWaitForParticipant from './components/Speeddate/SpeeddateWaitForParticipant';
import SpeeddateEnded from './components/Speeddate/SpeeddateEnded';
import CookieConsent from '../components/CookieConsent';
import Chat from './components/Chat';

const {
  api: { endpoint },
} = config;

interface MatchParams {
  identifier: string;
}

export type PlayerProps = RouteComponentProps<MatchParams>;

export interface PlayerState {
  // initialConnect: boolean;
  connected: boolean;
  identifier: string;
  registration?: TRegistration;
  registrationFailed: boolean;
  alreadyWatching: boolean;
  startTimer?: ReturnType<typeof setTimeout>;
  chat?: IChatMessage[];
  statement?: IStatement;
  message?: string;
  joinedBreakoutSession?: IPlayerJoinedBreakoutSession;
  breakoutSessionCountdown?: moment.Moment;
  speeddatesStartDate?: moment.Moment;
  joinedSpeeddate?: string;
  nextSpeeddateRound?: moment.Moment;
  speeddateRoundsRemaining?: number;
  waitForSpeeddateParticipant?: true;
  noNewSpeeddateOpponent?: boolean;
  survey?: ISurveyQuestion[];
  surveyInput?: string;
}

class Player extends React.Component<PlayerProps, PlayerState> {
  io: SocketIOClient.Socket = io(`${endpoint}`, {
    autoConnect: false,
  });

  notification = new Howl({
    src: '/static/audio/notifications.mp3',
    sprite: {
      maybe: [0, 2000],
      sure: [2000, 2000],
      undeniable: [4000, 2000],
      accomplished: [6000, 2000],
    },
    volume: 0.7,
  });

  constructor(props: PlayerProps) {
    super(props);

    const {
      match: {
        params: { identifier },
      },
    } = this.props;
    this.state = {
      // initialConnect: true,
      connected: false,
      identifier,
      registrationFailed: false,
      alreadyWatching: false,
      // joinedSpeeddate:
      //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJxYl93ZWJpbmFyIiwicm9vbSI6IjA0ZDYyZjY2LWIyZTEtNDNhYS1iN2EzLWRmOGE2Y2ExYWE2MyIsImV4cCI6MTYxMDI5ODE3MSwic3ViIjoicWJyb2FkY2FzdGluZyIsImF1ZCI6InFiX2JyZWFrb3V0IiwiY29udGV4dCI6eyJ1c2VyIjp7ImlkIjoiYWVmZWY4OGItZTNlZS00NDhhLWJjZDQtZWEyOWFhMzFkODkzIiwibmFtZSI6IlJlaW4gdmFuIEhhYXJlbiJ9LCJjYWxsZWUiOnsiaWQiOiJhZWZlZjg4Yi1lM2VlLTQ0OGEtYmNkNC1lYTI5YWEzMWQ4OTMiLCJuYW1lIjoiUmVpbiB2YW4gSGFhcmVuIn0sImdyb3VwIjoiMDRkNjJmNjYtYjJlMS00M2FhLWI3YTMtZGY4YTZjYTFhYTYzIn0sImlhdCI6MTYxMDI5NDU3MX0.fjx2EMKHPqk-gQyZhXrACjtwAXyHxBWkRMJP01mTXHo',
      // nextSpeeddateRound: moment().add(20, 'seconds'),
      // waitForSpeeddateParticipant: true,
      // statement: {
      //   id: 1,
      //   statement:
      //     'De CO2-uitstoot in grote steden moet beter worden aangepakt',
      //   answers: [
      //     { key: 0, answer: 'Gisteren dacht ik er nog anders over' },
      //     { key: 1, answer: 'Vraag me er morgen anders nog een keer naar' },
      //     {
      //       key: 2,
      //       answer:
      //         "Wellicht als je de directeur van Interpol belt dat'ie een nuttig antwoord kan geven",
      //     },
      //   ],
      // },
      // message:
      //   'Dit is een antwoord op bijvoorbeeld een vraag die gesteld is. Groetjes van een meneer die dit typt',
      // joinedBreakoutSession: {
      //   identifier: '26e58e12-9946-45b9-bd52-3f1c9850a72c',
      //   participantName: 'Rein van Haaren',
      //   roomName: 'aa TEST 1',
      //   withChat: true,
      // },
      // joinedBreakoutSession: {
      //   roomName: 'aa TEST 1',
      //   identifier: '61148ed0-db41-4981-952e-e2042588acbc',
      //   withChat: true,
      // },
    };
  }

  componentDidMount() {
    this.io.open();
    this.io.on('connect', this.handleConnect);
    this.io.on('disconnect', this.handleDisconnect);
    this.registerIoEventHandlers();
  }

  handleConnect = (): void => {
    const { identifier } = this.state;

    this.io.emit('registerPlayer', identifier, JSON.stringify(deviceDetect()));

    this.setState({ connected: true });
  };

  handleDisconnect = (): void =>
    this.setState({
      connected: false,
      statement: undefined,
      message: undefined,
      joinedBreakoutSession: undefined,
      joinedSpeeddate: undefined,
      speeddatesStartDate: undefined,
      nextSpeeddateRound: undefined,
      waitForSpeeddateParticipant: undefined,
      breakoutSessionCountdown: undefined,
    });

  registerIoEventHandlers = (): void => {
    this.io.on(
      'registered',
      (
        registration: TRegistration,
        speeddatesActive: number | false,
        chat?: IChatMessage[],
      ) => {
        this.setState({
          registration: {
            ...registration,
            webinar: {
              ...registration.webinar,
              // start,
            },
          },
          chat: chat?.map((c) => ({ ...c, type: 'incoming' })),
        });

        if (registration.webinar.webinarId === 164) {
          document.body.classList.add('samsung');

          i18n.changeLanguage('en-US');
        }

        this.handleSpeeddatesActive(speeddatesActive);

        this.setStartTimer(registration.webinar.start);

        document.title = registration.webinar.name;
      },
    );

    this.io.on('registrationFailed', () =>
      this.setState({ registrationFailed: true }),
    );

    this.io.on('alreadyWatching', () =>
      this.setState({ alreadyWatching: true }),
    );

    this.io.on('refreshPage', () => window.location.reload());

    this.io.on(
      'webinarState',
      (state: EWebinarState, stream?: IRegistrationWebinarStream) => {
        const { registration } = this.state;

        if (registration) {
          registration.webinar.stream = stream;
          registration.webinar.state = state;

          this.setState({ registration });
        }

        if (state === EWebinarState.pre) this.setState({ survey: undefined });
      },
    );

    this.io.on('chat', (chat?: IChatMessage[]) =>
      this.setState({ chat: chat?.map((c) => ({ ...c, type: 'incoming' })) }),
    );

    this.io.on('chatMessage', (message: IChatMessage) =>
      this.setState((s) => {
        const chat = s.chat?.concat({ ...message, type: 'incoming' });

        return { chat };
      }),
    );

    this.io.on('survey', (survey?: ISurveyQuestion[]) =>
      this.setState({ survey }),
    );

    this.io.on('statement', (statement: IStatement) => {
      if (statement) this.notification.play('sure');
      this.setState({ statement });
    });

    this.io.on('message', (message: string) => {
      this.notification.play('sure');
      this.setState({ message });
    });

    this.io.on('informationWindow', (informationWindow?: string) => {
      const { registration } = this.state;

      if (registration) {
        registration.webinar.informationWindow = informationWindow;

        this.setState({ registration });
      }
    });

    this.io.on(
      'breakoutSessions',
      (breakoutSessions: IRegistrationWebinarBreakoutSession[] | undefined) => {
        const { registration } = this.state;

        if (registration) {
          registration.webinar.breakoutSessions = breakoutSessions;

          this.setState({ registration });
        }

        if (!breakoutSessions)
          this.setState({
            joinedBreakoutSession: undefined,
            breakoutSessionCountdown: undefined,
          });
      },
    );

    this.io.on(
      'updateCanJoinBreakoutSession',
      (identifier: string, canJoin: boolean) => {
        const { registration } = this.state;

        if (registration) {
          registration.webinar.breakoutSessions = registration.webinar.breakoutSessions?.map(
            (b) => {
              if (b.identifier === identifier) return { ...b, canJoin };

              return b;
            },
          );

          this.setState({ registration });
        }
      },
    );

    this.io.on(
      'updateBreakoutSessionEnded',
      (identifier: string, ended: boolean) => {
        const { registration, joinedBreakoutSession } = this.state;

        if (
          joinedBreakoutSession &&
          joinedBreakoutSession?.identifier === identifier
        ) {
          toast.info(
            <Translation>
              {(t) =>
                `${t('webinar.breakoutSessions.endedNotification.pre')} ${
                  joinedBreakoutSession.roomName
                } ${t('webinar.breakoutSessions.endedNotification.post')}`
              }
            </Translation>,
          );

          this.setState({
            joinedBreakoutSession: undefined,
            breakoutSessionCountdown: undefined,
          });
        }

        if (registration) {
          registration.webinar.breakoutSessions = registration.webinar.breakoutSessions?.map(
            (b) => {
              if (b.identifier === identifier) return { ...b, ended };

              return b;
            },
          );

          this.setState({ registration });
        }
      },
    );

    this.io.on('breakoutSessionCountdown', (offset?: number) =>
      this.setState({
        breakoutSessionCountdown: offset
          ? moment().add(offset, 'seconds')
          : undefined,
      }),
    );

    this.io.on('speeddatesActive', this.handleSpeeddatesActive);

    this.io.on(
      'joinedSpeeddate',
      (
        joinedSpeeddate: string,
        secondsToNextRound: number,
        speeddateRoundsRemaining: number,
      ) => {
        // const { joinedSpeeddate: wasJoined } = this.state;
        const nextSpeeddateRound = moment().add(secondsToNextRound, 'seconds');

        this.setState({
          joinedSpeeddate,
          nextSpeeddateRound,
          speeddateRoundsRemaining,
          waitForSpeeddateParticipant: undefined,
          noNewSpeeddateOpponent: undefined,
        });
      },
    );

    this.io.on('noNewSpeeddateOpponent', (secondsToNextRound: number) => {
      const nextSpeeddateRound = moment().add(secondsToNextRound, 'seconds');

      this.setState({
        nextSpeeddateRound,
        joinedBreakoutSession: undefined,
        waitForSpeeddateParticipant: undefined,
        noNewSpeeddateOpponent: true,
      });
    });

    this.io.on('speeddateRoundsRemaining', (speeddateRoundsRemaining: number) =>
      this.setState({ speeddateRoundsRemaining }),
    );

    this.io.on('waitForSpeeddateParticipant', (secondsToNextRound: number) => {
      const nextSpeeddateRound = moment().add(secondsToNextRound, 'seconds');

      this.setState({ waitForSpeeddateParticipant: true, nextSpeeddateRound });
    });
  };

  handleSpeeddatesActive = (speeddatesActive: number | false) => {
    if (typeof speeddatesActive === 'number') {
      const speeddatesStartDate = moment().add(speeddatesActive, 'seconds');

      this.setState({ speeddatesStartDate });
    } else
      this.setState({
        speeddatesStartDate: undefined,
        joinedSpeeddate: undefined,
        nextSpeeddateRound: undefined,
        speeddateRoundsRemaining: undefined,
        waitForSpeeddateParticipant: undefined,
        noNewSpeeddateOpponent: undefined,
      });
  };

  setStartTimer = (start: string) => {
    const { startTimer } = this.state;
    const ms = moment(start).diff(moment(), 'milliseconds');
    let newTimer;

    if (startTimer) clearTimeout(startTimer);
    if (ms >= 0) newTimer = setTimeout(() => this.forceUpdate(), ms);

    this.setState({ startTimer: newTimer });
  };

  handleStatementAnswer = (id: number, answer: number) => {
    this.notification.play('maybe');

    this.io.emit('statementVote', id, answer);
    this.setState({ statement: undefined });
  };

  handleStatementDissmiss = () => {
    this.notification.play('maybe');
    this.setState({ statement: undefined });
  };

  handleMessageDissmiss = () => this.setState({ message: undefined });

  handleLeaveSpeeddate = () => {
    this.io.emit('leaveSpeeddates');

    this.setState({ joinedSpeeddate: undefined });
  };

  handleSendQuestion = (question: { name?: string; question: string }) => {
    this.notification.play('accomplished');
    this.io.emit('question', question);
  };

  handleJoinBreakoutSession = (values: IPlayerJoinedBreakoutSession): void => {
    this.setState(({ registration }) => ({
      joinedBreakoutSession: {
        ...values,
        participantName: registration?.registered
          ? registration.name
          : undefined,
      },
    }));
  };

  handleJoinSpeeddates = (name?: string): void => {
    if (name) localStorage.setItem('participantName', name);

    this.io.emit('joinSpeeddates', name);
  };

  handleRejoinSpeeddates = (): void => {
    const { registration } = this.state;
    const name = localStorage.getItem('participantName');
    let rejoin = true;

    if (registration?.webinar.nameRequired && name)
      this.io.emit('joinSpeeddates', name);
    else if (registration?.webinar.nameRequired === false)
      this.io.emit('joinSpeeddates');
    else rejoin = false;

    if (rejoin)
      this.setState({
        waitForSpeeddateParticipant: true,
        noNewSpeeddateOpponent: undefined,
      });
  };

  handleDissmissNoNewSpeeddateOpponent = (): void =>
    this.setState({
      joinedSpeeddate: undefined,
      nextSpeeddateRound: undefined,
      speeddateRoundsRemaining: undefined,
      waitForSpeeddateParticipant: undefined,
      noNewSpeeddateOpponent: undefined,
    });

  handleSendChatMessage = (m: IChatMessage): void => {
    const { chat } = this.state;
    const { name, message } = m;

    this.io.emit('chatMessage', {
      name,
      message,
    });

    if (chat) this.setState((s) => ({ chat: s.chat?.concat(m) }));
  };

  handleSetSurveyInput = (surveyInput: string): void =>
    this.setState({ surveyInput });

  handleSurveyQuestionAnswer = (id: number, answer?: string | number): void => {
    let { survey } = this.state;

    if (survey) {
      if (survey.length === 0 || (id === 0 && answer === 0)) survey = undefined;
      else {
        if (typeof answer !== 'undefined')
          this.io.emit('surveyAnswer', id, answer);

        survey.shift();
      }

      this.setState({ survey });
    }
  };

  render() {
    const {
      connected,
      registration,
      registrationFailed,
      alreadyWatching,
      chat,
      statement,
      message,
      joinedBreakoutSession,
      breakoutSessionCountdown,
      joinedSpeeddate,
      speeddatesStartDate,
      nextSpeeddateRound,
      speeddateRoundsRemaining,
      waitForSpeeddateParticipant,
      noNewSpeeddateOpponent,
      survey,
      surveyInput,
    } = this.state;

    const webinarState = registration?.webinar.state;
    const isGEA = (id: number) =>
      [76, 77, 78, 79, 80, 82, 83, 84, 86].some((w) => w === id);
    const isVOD = (id: number) =>
      [180, 190, 191, 209, 247].some((w) => w === id);

    return (
      <>
        <CookieConsent />
        {registrationFailed ? (
          <div className="container h-100 d-flex flex-column align-items-center mt-5">
            <h3 className="display-3">
              <i className="fas fa-times-circle fa-fw text-danger" />
            </h3>
            <h1 className="h2">
              <Translation>
                {(t) => t('webinar.registrationFailed.title')}
              </Translation>
              ...
            </h1>
            <span className="text-muted text-center">
              <Translation>
                {(t) => t('webinar.registrationFailed.text')}
              </Translation>
            </span>
          </div>
        ) : alreadyWatching ? (
          <div className="container h-100 d-flex flex-column align-items-center mt-5">
            <h3 className="display-3">
              <i className="fas fa-eye fa-fw text-success" />
            </h3>
            <h1 className="h2">
              <Translation>
                {(t) => t('webinar.alreadyWatching.title')}
              </Translation>
              ...
            </h1>
            <span className="text-muted text-center">
              <Translation>
                {(t) => t('webinar.alreadyWatching.text')}
              </Translation>
            </span>
          </div>
        ) : !registration ? (
          <div className="text-center mt-4">
            <div
              className="spinner-grow"
              style={{ width: '3rem', height: '3rem' }}
              role="status"
            >
              <span className="sr-only">
                <Translation>
                  {(t) =>
                    `${t('common.loading').charAt(0).toUpperCase()}${t(
                      'common.loading',
                    ).slice(1)}`
                  }
                </Translation>
                ...
              </span>
            </div>
          </div>
        ) : joinedBreakoutSession ? (
          <BreakoutSession
            joinedBreakoutSession={joinedBreakoutSession}
            breakoutSessionCountdown={breakoutSessionCountdown}
            settings={registration.webinar.settings}
            handleLeaveBreakoutSession={() =>
              this.setState({ joinedBreakoutSession: undefined })
            }
            handleSetBreakoutSessionEndsIn={(offset?: number) =>
              this.setState({
                breakoutSessionCountdown: offset
                  ? moment().add(offset, 's')
                  : undefined,
              })
            }
          />
        ) : noNewSpeeddateOpponent ? (
          <SpeeddateEnded
            settings={registration.webinar.settings}
            canRejoinSpeeddates={!!speeddatesStartDate}
            nextSpeeddateRound={nextSpeeddateRound}
            speeddateRoundsRemaining={speeddateRoundsRemaining}
            onDissmissNoNewSpeeddateOpponent={
              this.handleDissmissNoNewSpeeddateOpponent
            }
            onRejoinSpeeddates={this.handleRejoinSpeeddates}
          />
        ) : waitForSpeeddateParticipant && speeddatesStartDate ? (
          <SpeeddateWaitForParticipant
            nextSpeeddateRound={nextSpeeddateRound}
          />
        ) : // <div className="bg-black" style={{ position: 'fixed' }}>
        //   <ReactPlayer
        //     width="100vw"
        //     height="100vh"
        //     url="/static/video/speeddate_participant.webm"
        //     playing
        //     loop
        //   />
        // </div>
        joinedSpeeddate && speeddatesStartDate ? (
          <Speeddate
            token={joinedSpeeddate}
            nextSpeeddateRound={nextSpeeddateRound}
            speeddateRoundsRemaining={speeddateRoundsRemaining}
            onLeaveSpeeddate={this.handleLeaveSpeeddate}
          />
        ) : (
          <div className={`container${chat ? '-fluid' : ''}`}>
            <PlyrStyle
              textColor={registration.webinar.settings.textColor}
              backgroundColor={registration.webinar.settings.backgroundColor}
              accentColor={registration.webinar.settings.accentColor}
            />
            {webinarState === EWebinarState.pre ? (
              <>
                <Header registration={registration} />
                <Countdown
                  start={registration.webinar.start}
                  webinarName={registration.webinar.name}
                />
                <div className="mt-5">
                  <div id="content-wrapper">
                    <div id="player-wrapper">
                      <PlayerComponent
                        source={
                          registration.webinar.webinarId === 170
                            ? 'https://qbroadcasting-k.cdn.hostin.cc/video-dunea.mp4'
                            : `https://qbroadcasting-k.cdn.hostin.cc/dummy${
                                registration.webinar.lang === 'en' ? '_en' : ''
                              }.mp4`
                        }
                        hls={false}
                      />
                    </div>
                    {chat && (
                      <Chat
                        messages={chat}
                        connected={connected}
                        registration={registration}
                        onSendMessage={this.handleSendChatMessage}
                      />
                    )}
                  </div>
                </div>
              </>
            ) : webinarState === EWebinarState.post ? (
              <>
                <Header registration={registration} />

                <div id="content-wrapper">
                  <div
                    id="player-wrapper"
                    className={`${chat ? 'pw-post d-lg-block d-none' : ''}`}
                  >
                    {registration.webinar.webinarId === 174 ? (
                      <>
                        <h3 className="display-3">
                          <i className="fas fa-clock fa-fw text-secondary" />
                        </h3>
                        <h1 className="h2">Deze stream is afgelopen</h1>
                        <span className="text-muted">
                          Kijk mee naar de interactieve show van Rob Urgert en
                          Joep van Deudekom via{' '}
                          <a
                            href="https://vimeo.com/event/1733600"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://vimeo.com/event/1733600
                          </a>
                        </span>
                      </>
                    ) : registration.webinar.webinarId === 203 ||
                      registration.webinar.webinarId === 204 ? (
                      <AfterEndRabobank
                        survey={survey}
                        settings={registration.webinar.settings}
                        surveyInput={surveyInput}
                        onSurveyQuestionAnswer={this.handleSurveyQuestionAnswer}
                        onSetSurveyInput={this.handleSetSurveyInput}
                      />
                    ) : (
                      <AfterEnd
                        survey={survey}
                        settings={registration.webinar.settings}
                        surveyInput={surveyInput}
                        onSurveyQuestionAnswer={this.handleSurveyQuestionAnswer}
                        onSetSurveyInput={this.handleSetSurveyInput}
                      />
                    )}
                  </div>
                  {chat && (
                    <Chat
                      messages={chat}
                      connected={connected}
                      registration={registration}
                      onSendMessage={this.handleSendChatMessage}
                    />
                  )}
                </div>
              </>
            ) : (
              <>
                <Header registration={registration} />

                {registration.webinar.stream && (
                  <div id="content-wrapper" className="mb-4">
                    <div id="player-wrapper">
                      <VideoPlayer
                        stream={registration.webinar.stream}
                        statement={statement}
                        message={message}
                        nameRequired={registration.webinar.nameRequired}
                        disableQuestions={registration.webinar.disableQuestions}
                        settings={registration.webinar.settings}
                        speeddatesStartDate={speeddatesStartDate}
                        breakoutSessions={registration.webinar.breakoutSessions}
                        survey={survey}
                        surveyInput={surveyInput}
                        onStatementAnswer={this.handleStatementAnswer}
                        onStatementDissmiss={this.handleStatementDissmiss}
                        onSendQuestion={this.handleSendQuestion}
                        onMessageDissmiss={this.handleMessageDissmiss}
                        onJoinSpeeddates={this.handleJoinSpeeddates}
                        onSurveyQuestionAnswer={this.handleSurveyQuestionAnswer}
                        onSetSurveyInput={this.handleSetSurveyInput}
                        isVOD={isVOD(registration.webinar.webinarId)}
                      />
                    </div>
                    {chat && (
                      <Chat
                        messages={chat}
                        connected={connected}
                        registration={registration}
                        onSendMessage={this.handleSendChatMessage}
                      />
                    )}
                  </div>
                )}

                {registration.webinar.breakoutSessions && (
                  <BreakoutSessionSelector
                    className="pt-3"
                    breakoutSessions={registration.webinar.breakoutSessions}
                    settings={registration.webinar.settings}
                    handleJoinBreakoutSession={this.handleJoinBreakoutSession}
                  />
                )}
                {registration.webinar.informationWindow && (
                  <DivWithHTML
                    className="pt-3"
                    innerHtml={registration.webinar.informationWindow}
                  />
                )}
              </>
            )}

            <Footer
              logo={registration.webinar.logo}
              isGEA={isGEA(registration.webinar.webinarId)}
            />
          </div>
        )}
      </>
    );
  }
}

export default Player;

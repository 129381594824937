import * as React from 'react';

export interface DivWithHTMLProps {
  innerHtml: string;
  className?: string;
}

const DivWithHTML: React.SFC<DivWithHTMLProps> = ({
  innerHtml: __html,
  className,
}) => {
  return __html ? (
    <div
      className={className}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html }}
    />
  ) : null;
};

export default DivWithHTML;

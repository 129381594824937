import * as React from 'react';
import { Translation } from 'react-i18next';

export type HostSessionEndedProps = Record<string, unknown>;

export type HostSessionEndedState = Record<string, unknown>;

class HostSessionEnded extends React.Component<
  HostSessionEndedProps,
  HostSessionEndedState
> {
  componentDidMount() {
    document.body.style.height = '100%';
    document.documentElement.style.height = '100%';

    const root = document.getElementById('root');
    if (root) root.style.height = '100%';

    document.body.classList.add('host');
  }

  componentWillUnmount() {
    document.body.style.removeProperty('height');
    document.documentElement.style.removeProperty('height');

    const root = document.getElementById('root');
    if (root) root.style.removeProperty('height');
  }

  render() {
    return (
      <div className="h-100 row align-items-center">
        <div className="col" />
        <div className="col-10 col-xl-5">
          <div className="text-center">
            <div className="mb-3 align-items-stretch">
              <div className="card h-100">
                <div className="card-body d-flex flex-column">
                  <h4 className="display-4 mb-3">
                    <i className="fas fa-check-circle fa-fw text-success" />
                  </h4>
                  <h5 className="card-title">
                    <Translation>
                      {(t) =>
                        t('webinar.breakoutSessions.sessionHasEnded.title')
                      }
                    </Translation>
                  </h5>
                  <p className="card-text">
                    <Translation>
                      {(t) =>
                        t('webinar.breakoutSessions.sessionHasEnded.text')
                      }
                    </Translation>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col" />
      </div>
    );
  }
}

export default HostSessionEnded;

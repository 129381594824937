import * as React from 'react';
import Helmet from 'react-helmet';

export interface PlyrStyleProps {
  textColor: string;
  backgroundColor: string;
  accentColor: string;
}

const PlyrStyle: React.SFC<PlyrStyleProps> = ({
  textColor,
  backgroundColor,
  accentColor,
}) => {
  const toRgb = (hex: string): number[] => {
    const rgbHex = hex.match(/.{1,2}/g) as RegExpMatchArray;

    return [
      parseInt(rgbHex[0], 16),
      parseInt(rgbHex[1], 16),
      parseInt(rgbHex[2], 16),
    ];
  };

  const rgb = {
    textColor: toRgb(textColor.substr(1, 6)),
    backgroundColor: toRgb(backgroundColor.substr(1, 6)),
    accentColor: toRgb(accentColor.substr(1, 6)),
  };

  return (
    <Helmet>
      <style type="text/css">{`
            .plyr--audio .plyr__control.plyr__tab-focus,
            .plyr--audio .plyr__control:hover,
            .plyr--audio .plyr__control[aria-expanded="true"] {
              background: ${backgroundColor} !important;
            }

            .plyr--video .plyr__control.plyr__tab-focus,
            .plyr--video .plyr__control:hover,
            .plyr--video .plyr__control[aria-expanded="true"] {
              background: ${backgroundColor} !important;
            }

            .plyr__control--overlaid:focus,
            .plyr__control--overlaid:hover {
              background: ${backgroundColor} !important;
            }

            .plyr__menu__container
              .plyr__control[role="menuitemradio"][aria-checked="true"]::before {
              background: ${backgroundColor} !important;
            }

            .plyr--full-ui input[type="range"] {
              color: ${backgroundColor} !important;
            }

            .plyr__control--overlaid {
              background: rgba(${rgb.backgroundColor[0]}, ${rgb.backgroundColor[1]}, ${rgb.backgroundColor[2]}, 0.8) !important;
            }

            .plyr--full-ui
              input[type="range"].plyr__tab-focus::-webkit-slider-runnable-track {
              box-shadow: 0 0 0 5px rgba(${rgb.backgroundColor[0]}, ${rgb.backgroundColor[1]}, ${rgb.backgroundColor[2]}, 0.5) !important;
            }

            .plyr--full-ui input[type="range"].plyr__tab-focus::-moz-range-track {
              box-shadow: 0 0 0 5px rgba(${rgb.backgroundColor[0]}, ${rgb.backgroundColor[1]}, ${rgb.backgroundColor[2]}, 0.5) !important;
            }

            .plyr--full-ui input[type="range"].plyr__tab-focus::-ms-track {
              box-shadow: 0 0 0 5px rgba(${rgb.backgroundColor[0]}, ${rgb.backgroundColor[1]}, ${rgb.backgroundColor[2]}, 0.5) !important;
            }

            .plyr__control.plyr__tab-focus {
              box-shadow: 0 0 0 5px rgba(${rgb.backgroundColor[0]}, ${rgb.backgroundColor[1]}, ${rgb.backgroundColor[2]}, 0.5) !important;
            }
      `}</style>
    </Helmet>
  );
};

export default PlyrStyle;

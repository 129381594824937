import * as React from 'react';
import Plyr from 'plyr';
import Hls from 'hls.js';

export interface PlayerProps {
  className?: string;
  style?: React.CSSProperties;
  source: string;
  hls?: boolean;
  controls?: string[];
  poster: string;
  updatePlayerHeight?: () => void;
}

export type PlayerState = Record<string, unknown>;

class Player extends React.Component<PlayerProps, PlayerState> {
  player: Plyr | undefined;

  componentDidMount() {
    const { source: src, hls: loadHls = true, controls = [] } = this.props;
    const video = document.querySelector('video');

    if (video) {
      const options: Record<string, unknown> = {
        controls,
        settings: [],
        fullscreen: {
          enabled: true,
          iosNative: true,
          // fallback: 'force',
        },
      };

      if (loadHls && Hls.isSupported()) {
        const hls = new Hls();

        hls.loadSource(src);

        this.player = new Plyr(video, options);

        hls.attachMedia(video);
      } else {
        this.player = new Plyr(video, options);

        this.player.source = {
          type: 'video',
          sources: [
            {
              src,
              provider: 'html5',
            },
          ],
        };
      }
    }
  }

  componentDidUpdate() {
    const { updatePlayerHeight } = this.props;

    if (updatePlayerHeight) updatePlayerHeight();
  }

  render() {
    const { className, style, poster } = this.props;

    return (
      <video
        className={className}
        style={style}
        preload="none"
        id="player"
        poster={poster}
        crossOrigin="true"
        controls
        playsInline
      />
    );
  }
}

export default Player;

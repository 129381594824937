import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { IRegistrationWebinarSettings } from '../../../../interfaces/IRegistration';

import CustomButton from '../CustomButton';

export interface SurveyMultiplechoiceProps {
  question: string;
  settings: IRegistrationWebinarSettings;
  answers: string[];
  onMultiplechoiceClick: (answer: number) => void;
}

const SurveyMultiplechoice: React.SFC<SurveyMultiplechoiceProps> = ({
  question,
  settings: { textColor, backgroundColor, accentColor },
  answers,
  onMultiplechoiceClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className="mb-3">
      <h5 className="h5 mb-2 mb-md-4 font-italic">{question}</h5>
      <div className="mb-2">
        {answers.map((answer, index) => (
          <CustomButton
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className="mr-1 mr-md-2"
            text={`${index + 1}: ${answer}`}
            textColor={textColor}
            backgroundColor={backgroundColor}
            accentColor={accentColor}
            onClick={() => onMultiplechoiceClick(index)}
          />
        ))}
      </div>
      <small className="font-italic text-muted">
        {t('webinar.survey.chooseOneAnswer')}
      </small>
    </div>
  );
};

export default SurveyMultiplechoice;

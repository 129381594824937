import * as React from 'react';

import { TRegistration } from '../../../interfaces/IRegistration';
import EWebinarState from '../../../enums/EWebinar';

import StorageService from '../../../services/StorageService';

import DivWithHTML from './DivWithHTML';

import logoQBroadcasting from '../../../assets/images/qbroadcasting_logo.png';

export interface HeaderProps {
  registration: TRegistration;
}

const Header: React.FC<HeaderProps> = ({ registration }) => {
  const {
    webinar: { webinarId, name, state, logo, playerDescription },
  } = registration;

  return (
    <>
      <div className="position-relative my-5">
        {webinarId !== 142 && (
          <div className="col-12">
            <div className="text-center">
              <img
                className="mb-5"
                style={{ maxWidth: '85%' }}
                src={logo ? StorageService.logoUrl(logo) : logoQBroadcasting}
                alt="Logo"
              />
              <h2 className="display-5 font-weight-normal">{name}</h2>
            </div>
          </div>
        )}
        <div className="col-md-8 mx-auto">
          {state !== EWebinarState.post && playerDescription && (
            <DivWithHTML className="pt-3" innerHtml={playerDescription} />
          )}
        </div>
      </div>
    </>
  );
};

export default Header;

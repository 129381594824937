import { ITranslation } from '../../interfaces/ITranslation';

const translation: ITranslation = {
  common: {
    webinar: 'webinar',
    date: 'date',
    loading: 'loading',
    privacy: 'privacy',
    participant: 'Participant',
    participant_plural: 'Participants',
    participate: 'Participate',
    and: 'and',
    in: 'in ',
    send: 'Send',
    sent: 'Sent',
    skip: 'Skip',
    cancel: 'Cancel',
    close: 'Close',
    yourName: 'Your name',
    day: 'day',
    day_plural: 'days',
    hour: 'hour',
    hour_plural: 'hours',
    minute: 'minute',
    minute_plural: 'minutes',
    second: 'second',
    second_plural: 'seconds',
    notifications: {
      somethingWentWrong: 'Something went wrong. Try again later',
      serverError: 'The server gave an error',
    },
    cookieConsent: {
      mainText:
        'We place functional cookies to make this website work properly. No personal data is stored in these cookies.',
      acceptButton: 'I get it',
    },
  },
  registrationForm: {
    information: {
      title: 'Information',
      emailInstructions:
        'After registration you will receive an e-mail containing a unique link. By opening this link in your web browser at the start of the webinar, you can view the videostream and participate in the webinar. You will receive the same link again in your mailbox, no later than half an hour before the start of the webinar. A total of two messages will be sent.',
    },
    userData: {
      title: 'Data',
      firstname: 'First Name',
      lastname: 'Last name',
      company: 'Company',
      email: 'E-mail',
      phonenumber: 'Phonenumber',
      consent: {
        pre: 'Yes, I have taken note of ',
        link: 'privacy policy',
        post:
          'from Q Broadcasting and give explicit permission for processing of the data entered by me in order to participate in the webinar.',
      },
      submit: 'Register',
    },
    registered: {
      title: 'You are signed in',
      content:
        'You are registered for the webinar. Keep checking your email, you will receive more information soon.',
    },
    title: 'Registration form',
    registrationWebinar: 'Registration Webinar',
    registration: 'Registration',
    notifications: {
      noValidWebinarId: 'You have not provided a valid webinar ID. Try again',
      webinarNotFound: 'This webinar cannot be found. Try again',
      emailAlreadyExists:
        'There is already a registration at this e-mail address. You can only register once per e-mail address',
    },
  },
  unsubscribe: {
    unsubscribe: {
      title: 'Sign out...',
      text: "Too bad you don't want to receive any more messages",
    },
    unsubscribed: {
      title: 'Signed off',
      text:
        'You have been successfully unsubscribed from all e-mails from this webinar',
    },
    clickToUnsubscribe: 'Click here to unsubscribe',
    somethingWentWrong:
      'Something went wrong while signing out. Try again later',
    alreadyUnsubscribed:
      'The registration cannot be found. You have probably unsubscribed before',
  },
  webinar: {
    preWebinar: {
      tooEarly: 'We will start in...',
      aMomentOfPatience: 'Please wait. We are about to start!',
      untilStartOf: 'with',
    },
    registrationFailed: {
      title: 'Oops',
      text:
        'Something went wrong. Please contact the webinar organizer to resolve the issue.',
    },
    alreadyWatching: {
      title: 'Unfortunately ',
      text:
        'Someone is already watching via this link. Close the page on the device that is currently viewed at to continue here.',
    },
    webinarEnded: {
      title: 'The webinar has ended',
      text: 'The broadcast has stopped. Unfortunately you can no longer watch.',
    },
    player: {
      askAQuestion: 'Ask a Question',
      typeYourQuestion: 'Type your question here',
      questionSent: 'Your question has been sent to the studio',
      speeddatesStartIn: 'The speed dates starting in',
      speeddatesAreInProgress: 'The speed dates are in progress.',
      enterNameToParticipate: 'Enter your name to participate',
      pressButtonToParticipate: 'Press the button below to participate',
      statement: 'Statement',
      statement_plural: 'Statements',
      noOpinion: 'No opinion',
    },
    survey: {
      thankYouForWatching: 'Thank you for watching',
      weWouldAskQuestions:
        'We would like to ask you a short question about your experience with the webinar.',
      weWouldAskQuestions_plural:
        'We would like to ask you a few short questions about your experience with the webinar.',
      thankYouForYourResponse:
        'Thank you for your response. There are no more questions.',
      typeYourAnswer: 'Type your answer here',
      chooseOneAnswer: 'Choose one answer',
    },
    breakoutSessions: {
      breakoutSession: 'Breakout session',
      breakoutSession_plural: 'Breakout sessions',
      preBreakoutSession: 'the breakout session',
      preBreakoutSession_plural: 'one of the breakout sessions',
      breakoutSessionsStarted:
        'The breakout sessions have started. Scroll down to participate.',
      sessionHasEnded: {
        title: 'Session ended',
        text: 'The session has ended. You can now close this window.',
      },
      sessionIsEnded: 'This session has ended',
      participateIn: 'Participate in',
      waitingForHost: 'We are waiting for the host',
      enterNameToParticipate: 'Enter your name to participate',
      onlyOnPc: 'Open this page on a PC or laptop to participate in',
      hostNotPresent:
        'The host of this session is not yet present. Please try again later',
      moderatorKickedYou: 'The moderator has removed you from the session',
      isLoading: 'is loading',
      mediaConsent:
        'You will be asked for permission to use your camera and microphone. This permission is only valid for this page and will only be used to enable participation in the session.',
      leftSession: 'You have left the session',
      endedNotification: { pre: 'The breakout session', post: 'has ended' },
      endOfBreakoutSession: 'End of this session',
      host: {
        hostASession: 'Host a breakout session',
        yourNameHelp:
          'Your name is visible to all participants in the breakout session',
        yourEmail: 'Your e-mail address',
        login: 'login',
        of: 'of',
        subject: 'Topic',
        changeSubjectOfSession: 'Change the topic of the session',
        changeSubject: 'Change topic',
        muteEveryone: 'Mute everyone',
      },
      notifications: {
        noValidBreakoutSessionId:
          'You have not provided a valid breakout session ID. Try again',
        sessionNotFound: 'This breakout session cannot be found. Try again',
        noAccessToSession:
          'Unfortunately you cannot access this breakout session. Request a (new) link from your contact person',
      },
    },
    speeddates: {
      speeddate: 'Speed ​​date',
      speeddate_plural: 'Speed ​​dates',
      theSpeeddate: 'The speed date',
      willStartSpeeddates: 'the speed dates will start',
      speeddatesAlmostStart: 'The speed dates will start soon',
      searchForParticipant: "We're looking for a participant",
      nextRoundIn: 'Next round in',
      nextRound: 'Next round',
      lastRound: 'Last round',
      endOfSpeeddates: 'End of the speed dates',
      isGettingStarted: 'is getting started',
      clickToGoBack: 'Click here to go back',
      clickToWait: 'Click here to participate again',
      noParticipants: {
        title: 'There are no participants',
        text:
          'Unfortunately there is no one to start a speed date with. When you press the button below your history is reset and it is possible to speak again with the same person.',
      },
    },
  },
};

export default translation;

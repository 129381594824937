/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import config from '../config';

const { endpoint, prefix } = config.api;
/** Set baseURL to API endpoint from envorinment-file */
axios.defaults.baseURL = `${endpoint}${prefix}`;

/** Create success handlers */
const successHandler = (response: AxiosResponse<any>) => response;

/** Create error handlers */
const errorHandler = (error: any) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    toast.error('Something went wrong. Try again later');
  }

  return Promise.reject(error);
};

/** Intercept errors and handle them in a Toastr */
axios.interceptors.response.use(successHandler, errorHandler);

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
};

import * as React from 'react';
import { Translation } from 'react-i18next';

export interface TranslateStringProps {
  i18nKey: string;
  count?: number;
  uppercase?: 'none' | 'first' | 'all';
}

const TranslateString: React.FC<TranslateStringProps> = ({
  i18nKey,
  count,
  uppercase,
}) => {
  return (
    <Translation>
      {(t) => {
        const string = t(i18nKey, { count });

        if (uppercase === 'all') return string.toUpperCase();
        if (uppercase === 'none') return string.toLowerCase();
        if (uppercase === 'first')
          return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;

        return string;
      }}
    </Translation>
  );
};

export default TranslateString;

import * as React from 'react';
import { Translation } from 'react-i18next';

import { IHostInfo } from '../../../interfaces/IHost';
import { IJitsiParticipant } from '../../../interfaces/IJitsi';

import CustomButton from '../../player/components/CustomButton';

export interface HostSessionHeaderProps {
  className?: string;
  hostInfo: IHostInfo;
  videoConferenceJoined: boolean;
  participants: IJitsiParticipant[];
  customSubject?: string;
  handleSetSubject: (subject: string) => void;
  handleMuteEveryone: () => void;
  handleEndSession: () => void;
}

const HostSessionHeader: React.SFC<HostSessionHeaderProps> = ({
  hostInfo: {
    name,
    limit,
    settings: { textColor, backgroundColor, accentColor },
  },
  className,
  videoConferenceJoined,
  participants,
  customSubject,
  handleSetSubject,
  handleMuteEveryone,
  // handleEndSession,
}) => {
  const [subject, setSubject] = React.useState('');

  return (
    <div className={`d-flex flex-row align-items-center ${className}`}>
      <div className="mr-3" style={{ minWidth: limit > 0 ? 140 : 100 }}>
        <h5 className="h5 mb-0">{name}</h5>
        <small className="text-muted">
          <Translation>
            {(t) => t('common.participant', { count: 2 })}
          </Translation>
          : {participants.length}
          {limit > 0 && (
            <Translation>
              {(t) => `${t('webinar.breakoutSessions.host.of')} ${limit}`}
            </Translation>
          )}
        </small>
      </div>
      <div className="mr-2 flex-fill">
        <Translation>
          {(t) => (
            <input
              className="form-control form-control-sm"
              type="text"
              placeholder={
                customSubject
                  ? `${t('webinar.breakoutSessions.host.subject')}: ${subject}`
                  : t('webinar.breakoutSessions.host.changeSubjectOfSession')
              }
              value={subject}
              onChange={({ currentTarget: { value } }) => setSubject(value)}
            />
          )}
        </Translation>
      </div>
      <div>
        <Translation>
          {(t) => (
            <CustomButton
              className="btn-success btn-sm"
              text={t('webinar.breakoutSessions.host.changeSubject')}
              icon="exchange-alt"
              onClick={() => {
                handleSetSubject(subject);
                setSubject('');
              }}
              disabled={!videoConferenceJoined || !subject}
            />
          )}
        </Translation>
      </div>
      <div className="topbar-divider" />
      <div className="mr-2">
        <Translation>
          {(t) => (
            <CustomButton
              className="btn-sm"
              text={t('webinar.breakoutSessions.host.muteEveryone')}
              textColor={textColor}
              backgroundColor={backgroundColor}
              accentColor={accentColor}
              icon="microphone-slash"
              onClick={handleMuteEveryone}
              disabled={!videoConferenceJoined}
            />
          )}
        </Translation>
      </div>
      {/* <div>
        <CustomButton
          className="btn-danger btn-sm"
          text="Sessie beëindigen"
          icon="times-circle"
          onClick={handleEndSession}
          disabled={!videoConferenceJoined}
        />
      </div> */}
    </div>
  );
};

export default HostSessionHeader;
